import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";
import { useSelector } from "react-redux";
import { GET } from "../../utils/apiCalls";
import { API } from "../../config/api";
import "./styles.scss"; // Import the SCSS file
import { Col, Container, Row } from "react-bootstrap";
import { Card, Pagination, notification } from "antd";
import CourseScreen from "../../backup/courses";
import EmptyBox from "../../components/emptyBox";
import LoadingBox from "../../components/loading";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [enrollmentData, setEnrollmentData] = useState<any[]>([]);
  const [dataCount, setDataCount] = useState<any>({});
  const [dataCounts, setDataCounts] = useState<any>({});
  const [courses, setCourses] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const [page, setPage] = useState(1);
  const user: any = useSelector((val: any) => val.User.user);
  const navigate = useNavigate();

  useEffect(() => {
    loadData();
    getAllData();
    getAllDat();
    loadDatas(page, 6);
  }, [page]);

  const data = enrollmentData.map((val, index) => ({
    courseTitle: val?.course?.titleOne,
    progress: val?.progress,
  }));

  const loadData = async () => {
    try {
      let url = API.GET_USER_ENROLLEMENT + user.id;
      const enrollment: any = await GET(url, {});
      if (enrollment?.status) {
        setEnrollmentData(enrollment?.data);
        setIsLoading(false);
      } else {
        setEnrollmentData([]);
        setIsLoading(false);
        // notification.error({ message: "No data found" });
      }
    } catch (err) {
      setIsLoading(false);
      notification.error({ message: "Server error" });
    }
  };

  const getAllData = async () => {
    try {
      let api = API.GET_COUNT_ENROLLEMENT + user.id;
      let count: any = await GET(api, {});
      if (count?.status) {
        setDataCount(count?.data);
        setIsLoading(false);
      } else {
        setDataCount([]);
        setIsLoading(false);
        // notification.error({ message: "No data found" });
      }
    } catch (err) {
      setIsLoading(false);
      notification.error({ message: "Server error" });
    }
  };

  const getAllDat = async () => {
    try {
      let api = API.GET_COUNT;
      const count: any = await GET(api, {});
      if (count?.status) {
        setDataCounts(count?.data);
        setIsLoading(false);
      } else {
        setDataCounts([]);
        setIsLoading(false);
        // notification.error({ message: "No data found" });
      }
    } catch (err) {
      setIsLoading(false);
      notification.error({ message: "Server error" });
    }
  };

  const loadDatas = async (page: any, take: any) => {
    setIsLoading(true);
    try {
      let url = API.GET_COURSES + `?order=ASC&page=${page}&take=${take}`;
      const response: any = await GET(url, {});
      if (response?.status) {
        setCourses(response?.data);
        setMeta(response?.meta);
        setIsLoading(false);
      } else {
        setCourses([]);
        setIsLoading(false);
        // notification.error({ message: "No data found" });
      }
    } catch (err) {
      setIsLoading(false);
      notification.error({ message: "Server error" });
    }
  };

  const pagination = (currentPage: any, pageSize: any) => {
    setPage(currentPage);
    loadDatas(currentPage, 6);
  };

  return (
    <div className="main-box">
      <Row>
        <Col md="6">
          <div className=""></div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={3}>
          <Card className="" onClick={() => navigate(`/Auth/enrolements`)}>
            <div className="">Enrollments</div>
            <div className="">{dataCount?.data}</div>
          </Card>
        </Col>
        <Col md={3}>
          <Card onClick={() => navigate(`/Auth/courses`)}>
            <div className="">Courses</div>
            <div className="">{dataCounts?.course}</div>
          </Card>
        </Col>
        {/* <Col md={3}>
          <Card>
            <div className="">Course completed</div>
            <div className="">1</div>
          </Card>
        </Col>
        <Col md={3}>
          <Card>
            <div className="">Certifications</div>
            <div className="">2</div>
          </Card>
        </Col> */}
      </Row>
      <br />
      {data?.length ? (
        <Row>
          <Col md="12">
            <Card>
              <BarChart
                width={window.innerWidth < 576 ? window.innerWidth * 0.9 : 700}
                height={window.innerWidth < 576 ? 200 : 300}
                data={data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                style={{ width: "100%" }}
              >
                <XAxis dataKey="courseTitle" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="progress" fill="#8884d8" barSize={50} />
              </BarChart>
            </Card>
          </Col>
        </Row>
      ) : null}
      <br />
      <div className="main-box">
        {isLoading ? (
          <LoadingBox />
        ) : courses?.length ? (
          <div className="">
            <Card style={{ height: "100vh" }}>
              <Row>
                {courses?.map((cat: any) => (
                  <Col xs={12} sm={6} md={3} key={cat.id}>
                    <Card
                      className="cat-card"
                      onClick={() => navigate(`/Auth/details/${cat?.id}`)}
                    >
                      <img
                        src={cat.image}
                        alt="image"
                        style={{
                          width: "100%",
                          height: "150px",
                          objectFit: "cover",
                        }}
                      />
                      <p className="card-titleone">{cat.titleOne}</p>
                      <p className="card-titletwo">{cat.titleTwo}</p>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Pagination
                current={page}
                pageSize={meta.take}
                total={meta.itemCount}
                onChange={pagination}
              />
            </Card>
          </div>
        ) : (
          <EmptyBox title={"Categories"} />
        )}
      </div>
    </div>
  );
};

export default Dashboard;

import { Card, Spin } from "antd";
import React from "react";
import Loading from "../../assets/images/loading44.gif";

const LoadingBox = () => {
  return (
    <div>
      <Card
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={Loading} style={{ width: "100%", objectFit: "fill" }} />
      </Card>
    </div>
  );
};

export default LoadingBox;

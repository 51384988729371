// import React, { useEffect, useState } from "react";
// import { Col, Row, ToastContainer } from "react-bootstrap";
// import YouTube from "react-youtube";
// import { useLocation, useParams, useNavigate } from "react-router-dom";
// import { API } from "../../config/api";
// import { GET, POST, PUT } from "../../utils/apiCalls";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { useDispatch, useSelector } from "react-redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { FaLock } from "react-icons/fa6";
// import { IoCheckmarkCircle } from "react-icons/io5";
// import { Button, Card, notification, Spin } from "antd";
// import Meta from "antd/es/card/Meta";
// import { CiLock } from "react-icons/ci";
// import { IoIosArrowForward } from "react-icons/io";
// import { updateEnrolement } from "../../redux/slice/courseSlice";
// import LoadingBox from "../../components/loading";

// const LessonScreen = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { moduleId, lessonId, courseId } = useParams();
//   const [lessonData, setLessonData] = useState<any>([]);
//   const [showData, setShowData] = useState<any>(null);
//   const [order, setOrder] = useState<any>(null);
//   const [loading, setLoading] = useState(false);
//   const [loading2, setLoading2] = useState(false);
//   const [loading3, setLoading3] = useState(false);
//   const [lessonList, setLessonlist] = useState<any>([]);

//   const Course: any = useSelector((val: any) => val.Course);

//   const User: any = useSelector((val: any) => val.User.user);
//   const [userProgresses, setUserProgresses] = useState<any>([]);

//   const [userprogresses, setUserprogresses] = useState<any>([]);

//   const [progressList, setProgressList] = useState<any>([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [enrollment, setEnrollement] = useState<any>({});
//   const dispatch = useDispatch();

//   console.log("progressList --> ", progressList);

//   useEffect(() => {
//     loadData();
//     getProgress();
//   }, [Course]);

//   const loadData = async () => {
//     try {
//       setLoading2(true);
//       const api =
//         API.GET_COURSE_LESSON + moduleId + `?order=ASC&page=1&take=50`;
//       const lesson: any = await GET(api, {});
//       if (lesson?.status) {
//         setLessonlist(lesson?.data);
//         console.log("redux Course ======> ", Course);
//         let lsId = Course?.enrolement?.lessonId;
//         const foundObject = await lesson?.data?.find(
//           (obj: any) => obj?.id === String(lsId)
//         );
//         let newOrder = Number(foundObject?.order);
//         const data = await lesson?.data?.find(
//           (obj: any) => obj?.order === newOrder
//         );
//         console.log("final data ====== > ", data);
//         setShowData(data);
//         setLoading2(false);
//       } else {
//         setLessonlist([]);
//         setLoading2(false);
//       }
//     } catch (error) {
//       console.log(error);
//       setLoading2(false);
//     }
//   };

//   const getProgress = async () => {
//     try {
//       setLoading2(true);
//       const api = "userprogress/progress";
//       let body = {
//         userId: Number(User?.id),
//         moduleId: Number(moduleId),
//         type: "lesson",
//       };
//       const progress: any = await POST(api, body);
//       if (progress?.status) {
//         setUserProgresses(progress?.data);
//         let arr: any = [];
//         progress?.data?.map((mod: any) => {
//           arr.push(String(mod?.lessonId));
//         });
//         setProgressList(arr);

//         let lsId = Course?.enrolement?.lessonId;
//         const foundObject = await lessonList?.data?.find(
//           (obj: any) => obj?.id === String(lsId)
//         );
//         let newOrder = Number(foundObject?.order);
//         const data = await lessonList?.data?.find(
//           (obj: any) => obj?.order === newOrder
//         );

//         setLoading2(false);
//       } else {
//         setUserProgresses([]);
//         setLoading2(false);
//       }
//     } catch (error) {
//       setLoading2(false);
//       console.log(error);
//     }
//   };

//   const getEnrolement = async () => {
//     try {
//       setLoading2(true);
//       let api = "Enrollments/list";
//       let obj = {
//         userId: Number(User?.id),
//         courseId: Number(showData?.courseId),
//         page: 1,
//         take: 10,
//       };
//       let enrolement: any = await POST(api, obj);
//       if (enrolement?.status) {
//         console.log(enrolement?.data[0]);
//         setEnrollement(enrolement?.data[0]);
//         dispatch(updateEnrolement(enrolement?.data[0]));
//         setLoading2(false);
//       } else {
//         setEnrollement({});
//         setLoading2(false);
//       }
//     } catch (error) {
//       console.log(error);
//       setLoading2(false);
//     }
//   };

//   const onClickNext = async () => {
//     try {
//       setLoading2(true);
//       console.log("showData ++++++ ", showData);
//       let api = "lesson/nextLesson";
//       let body = {
//         lessonId: Number(Course?.enrolement?.lessonId),
//         moduleId: Number(Course?.enrolement?.moduleId),
//         userId: Number(User.id),
//         courseId: Number(Course?.enrolement?.courseId),
//         order: Number(showData?.order),
//       };
//       const nextLesson: any = await POST(api, body);
//       if (nextLesson?.status) {
//         dispatch(updateEnrolement(nextLesson?.data?.enrolement));
//         getEnrolement();
//         getProgress();
//         loadData();
//         setLoading2(false);
//       }
//     } catch (error) {
//       setLoading2(false);
//       console.log(error);
//     }
//   };

//   console.log("showData --> ", showData);

//   const handleGoBack = () => {
//     navigate(-1);
//   };

//   const allLessonsCompleted = () => {
//     return progressList.length === lessonList.length;
//   };

//   const onClick = (data: any) => {
//     setLoading2(true);
//     setShowData(data);
//     setLoading2(false);
//   };

//   return (
//     <div>
//       <div className="main-box">
//         <Row>
//           <Col>
//             <Card>
//               <h2>{showData?.title}</h2>
//               <br />
//               {loading2 ? (
//                 <LoadingBox />
//               ) : (
//                 <YouTube videoId={showData?.video} opts={{ width: "100%" }} />
//               )}
//               <br />
//               <p>{showData?.description}</p>
//               <br />

//               {allLessonsCompleted() ? (
//                 <Button
//                   onClick={() => navigate(`/Auth/quiz/${moduleId}`)}
//                   style={{ margin: "10px 0", borderRadius: "20px" }}
//                 >
//                   Quiz
//                 </Button>
//               ) : (
//                 <>
//                   {order === lessonList.length ? (
//                     <Button
//                       onClick={() => navigate(`/Auth/quiz/${moduleId}`)}
//                       style={{ margin: "10px 0", borderRadius: "20px" }}
//                     >
//                       Quiz
//                     </Button>
//                   ) : (
//                     <Button
//                       onClick={() => onClickNext()}
//                       style={{ margin: "10px 0", borderRadius: "20px" }}
//                     >
//                       Next Lesson
//                     </Button>
//                   )}
//                 </>
//               )}
//             </Card>
//           </Col>
//           <Col md="3">
//             <Card className="stickyBox">
//               <div className="">All Lessons</div>
//               {lessonList?.map((lesson: any) => {
//                 return (
//                   <>
//                     <Card
//                       loading={loading2}
//                       onClick={() => onClick(lesson)}
//                       className={`module-card ${
//                         !progressList.includes(String(lesson?.id))
//                           ? "locked"
//                           : ""
//                       }`}
//                     >
//                       <Meta
//                         avatar={
//                           progressList.includes(String(lesson?.id)) ? (
//                             <IoCheckmarkCircle size={25} color={"green"} />
//                           ) : (
//                             <CiLock size={25} color={"#000"} />
//                           )
//                         }
//                         title={lesson?.title}
//                         description={
//                           <div className="">
//                             {progressList.includes(String(lesson?.id))
//                               ? "Unlocked"
//                               : "Locked"}
//                           </div>
//                         }
//                       />
//                     </Card>
//                   </>
//                 );
//               })}
//             </Card>
//           </Col>
//         </Row>
//       </div>
//     </div>
//   );
// };

// export default LessonScreen;
// import React, { useEffect, useState } from "react";
// import { Col, Row, ToastContainer } from "react-bootstrap";
// import YouTube from "react-youtube";
// import { useLocation, useParams, useNavigate } from "react-router-dom";
// import { API } from "../../config/api";
// import { GET, POST, PUT } from "../../utils/apiCalls";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { useDispatch, useSelector } from "react-redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { FaLock } from "react-icons/fa6";
// import { IoCheckmarkCircle } from "react-icons/io5";
// import { Button, Card, notification, Spin } from "antd";
// import Meta from "antd/es/card/Meta";
// import { CiLock } from "react-icons/ci";
// import { IoIosArrowForward } from "react-icons/io";
// import { updateEnrolement } from "../../redux/slice/courseSlice";
// import LoadingBox from "../../components/loading";

// const LessonScreen = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { moduleId, lessonId, courseId } = useParams();
//   const [lessonData, setLessonData] = useState<any>([]);
//   const [showData, setShowData] = useState<any>(null);
//   const [order, setOrder] = useState<any>(null);
//   const [loading, setLoading] = useState(false);
//   const [loading2, setLoading2] = useState(false);
//   const [loading3, setLoading3] = useState(false);
//   const [lessonList, setLessonlist] = useState<any>([]);

//   const Course: any = useSelector((val: any) => val.Course);

//   const User: any = useSelector((val: any) => val.User.user);
//   const [userProgresses, setUserProgresses] = useState<any>([]);

//   const [userprogresses, setUserprogresses] = useState<any>([]);

//   const [progressList, setProgressList] = useState<any>([]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [enrollment, setEnrollement] = useState<any>({});
//   const dispatch = useDispatch();

//   console.log("progressList --> ", progressList);

//   useEffect(() => {
//     loadData();
//     getProgress();
//   }, [Course]);

//   const loadData = async () => {
//     try {
//       setLoading2(true);
//       const api =
//         API.GET_COURSE_LESSON + moduleId + `?order=ASC&page=1&take=50`;
//       const lesson: any = await GET(api, {});
//       if (lesson?.status) {
//         setLessonlist(lesson?.data);
//         console.log("redux Course ======> ", Course);
//         let lsId = Course?.enrolement?.lessonId;
//         const foundObject = await lesson?.data?.find(
//           (obj: any) => obj?.id === String(lsId)
//         );
//         let newOrder = Number(foundObject?.order);
//         const data = await lesson?.data?.find(
//           (obj: any) => obj?.order === newOrder
//         );
//         console.log("final data ====== > ", data);
//         setShowData(data);
//         setLoading2(false);
//       } else {
//         setLessonlist([]);
//         setLoading2(false);
//       }
//     } catch (error) {
//       console.log(error);
//       setLoading2(false);
//     }
//   };

//   const getProgress = async () => {
//     try {
//       setLoading2(true);
//       const api = "userprogress/progress";
//       let body = {
//         userId: Number(User?.id),
//         moduleId: Number(moduleId),
//         type: "lesson",
//       };
//       const progress: any = await POST(api, body);
//       if (progress?.status) {
//         setUserProgresses(progress?.data);
//         let arr: any = [];
//         progress?.data?.map((mod: any) => {
//           arr.push(String(mod?.lessonId));
//         });
//         setProgressList(arr);

//         let lsId = Course?.enrolement?.lessonId;
//         const foundObject = await lessonList?.find(
//           (obj: any) => obj?.id === String(lsId)
//         );
//         let newOrder = Number(foundObject?.order);
//         const data = await lessonList?.find(
//           (obj: any) => obj?.order === newOrder
//         );

//         setLoading2(false);
//       } else {
//         setUserProgresses([]);
//         setLoading2(false);
//       }
//     } catch (error) {
//       setLoading2(false);
//       console.log(error);
//     }
//   };

//   const getEnrolement = async () => {
//     try {
//       setLoading2(true);
//       let api = "Enrollments/list";
//       let obj = {
//         userId: Number(User?.id),
//         courseId: Number(showData?.courseId),
//         page: 1,
//         take: 10,
//       };
//       let enrolement: any = await POST(api, obj);
//       if (enrolement?.status) {
//         console.log(enrolement?.data[0]);
//         setEnrollement(enrolement?.data[0]);
//         dispatch(updateEnrolement(enrolement?.data[0]));
//         setLoading2(false);
//       } else {
//         setEnrollement({});
//         setLoading2(false);
//       }
//     } catch (error) {
//       console.log(error);
//       setLoading2(false);
//     }
//   };

//   const onClickNext = async () => {
//     try {
//       setLoading2(true);
//       console.log("showData ++++++ ", showData);
//       let api = "lesson/nextLesson";
//       let body = {
//         lessonId: Number(Course?.enrolement?.lessonId),
//         moduleId: Number(Course?.enrolement?.moduleId),
//         userId: Number(User.id),
//         courseId: Number(Course?.enrolement?.courseId),
//         order: Number(showData?.order),
//       };
//       const nextLesson: any = await POST(api, body);
//       if (nextLesson?.status) {
//         dispatch(updateEnrolement(nextLesson?.data?.enrolement));
//         getEnrolement();
//         getProgress();
//         loadData();
//         setLoading2(false);
//       }
//     } catch (error) {
//       setLoading2(false);
//       console.log(error);
//     }
//   };

//   console.log("showData --> ", showData);

//   const handleGoBack = () => {
//     navigate(-1);
//   };

//   const allLessonsCompleted = () => {
//     return progressList.length === lessonList.length;
//   };

//   const onClick = (data: any) => {
//     if (!progressList.includes(String(data?.id))) {
//       toast.error("This lesson is locked.");
//       return;
//     }
//     setLoading2(true);
//     setShowData(data);
//     setLoading2(false);
//   };

//   return (
//     <div>
//       <div className="main-box">
//         <Row>
//           <Col>
//             <Card>
//               <h2>{showData?.title}</h2>
//               <br />
//               {loading2 ? (
//                 <LoadingBox />
//               ) : (
//                 <YouTube videoId={showData?.video} opts={{ width: "100%" }} />
//               )}
//               <br />
//               <p>{showData?.description}</p>
//               <br />

//               {allLessonsCompleted() ? (
//                 <Button
//                   onClick={() => navigate(`/Auth/quiz/${moduleId}`)}
//                   style={{ margin: "10px 0", borderRadius: "20px" }}
//                 >
//                   Quiz
//                 </Button>
//               ) : (
//                 <>
//                   {order === lessonList.length ? (
//                     <Button
//                       onClick={() => navigate(`/Auth/quiz/${moduleId}`)}
//                       style={{ margin: "10px 0", borderRadius: "20px" }}
//                     >
//                       Quiz
//                     </Button>
//                   ) : (
//                     <Button
//                       onClick={() => onClickNext()}
//                       style={{ margin: "10px 0", borderRadius: "20px" }}
//                     >
//                       Next Lesson
//                     </Button>
//                   )}
//                 </>
//               )}
//             </Card>
//           </Col>
//           <Col md="3">
//             <Card className="stickyBox">
//               <div className="">All Lessons</div>
//               {lessonList?.map((lesson: any) => {
//                 return (
//                   <Card
//                     key={lesson?.id}
//                     loading={loading2}
//                     onClick={() => onClick(lesson)}
//                     className={`module-card ${
//                       !progressList.includes(String(lesson?.id)) ? "locked" : ""
//                     }`}
//                   >
//                     <Meta
//                       avatar={
//                         progressList.includes(String(lesson?.id)) ? (
//                           <IoCheckmarkCircle size={25} color={"green"} />
//                         ) : (
//                           <CiLock size={25} color={"#000"} />
//                         )
//                       }
//                       title={lesson?.title}
//                       description={
//                         <div className="">
//                           {progressList.includes(String(lesson?.id))
//                             ? "Unlocked"
//                             : "Locked"}
//                         </div>
//                       }
//                     />
//                   </Card>
//                 );
//               })}
//             </Card>
//           </Col>
//         </Row>
//       </div>
//       <ToastContainer />
//     </div>
//   );
// };

// export default LessonScreen;
import React, { useEffect, useState } from "react";
import { Col, Row, ToastContainer } from "react-bootstrap";
import YouTube from "react-youtube";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { API } from "../../config/api";
import { GET, POST, PUT } from "../../utils/apiCalls";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaLock } from "react-icons/fa6";
import { IoCheckmarkCircle } from "react-icons/io5";
import { Button, Card, notification, Spin } from "antd";
import Meta from "antd/es/card/Meta";
import { CiLock } from "react-icons/ci";
import { IoIosArrowForward } from "react-icons/io";
import { updateEnrolement } from "../../redux/slice/courseSlice";
import LoadingBox from "../../components/loading";
import Header from "../../components/header";

const LessonScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { moduleId, lessonId, courseId } = useParams();
  const [lessonData, setLessonData] = useState<any>([]);
  const [showData, setShowData] = useState<any>(null);
  const [order, setOrder] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [lessonList, setLessonlist] = useState<any>([]);

  const Course: any = useSelector((val: any) => val.Course);

  const User: any = useSelector((val: any) => val.User.user);
  const [userProgresses, setUserProgresses] = useState<any>([]);

  const [userprogresses, setUserprogresses] = useState<any>([]);

  const [progressList, setProgressList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [enrollment, setEnrollement] = useState<any>({});
  const dispatch = useDispatch();

  console.log("progressList --> ", progressList);

  useEffect(() => {
    loadData();
    getProgress();
  }, [Course]);

  const loadData = async () => {
    try {
      setLoading2(true);
      const api =
        API.GET_COURSE_LESSON + moduleId + `?order=ASC&page=1&take=50`;
      const lesson: any = await GET(api, {});
      if (lesson?.status) {
        setLessonlist(lesson?.data);
        console.log("redux Course ======> ", Course);
        let lsId = Course?.enrolement?.lessonId;
        const foundObject = await lesson?.data?.find(
          (obj: any) => obj?.id === String(lsId)
        );
        let newOrder = Number(foundObject?.order);
        const data = await lesson?.data?.find(
          (obj: any) => obj?.order === newOrder
        );
        console.log("final data ====== > ", data);
        setShowData(data);
        setLoading2(false);
      } else {
        setLessonlist([]);
        setLoading2(false);
      }
    } catch (error) {
      console.log(error);
      setLoading2(false);
    }
  };

  const getProgress = async () => {
    try {
      setLoading2(true);
      const api = "userprogress/progress";
      let body = {
        userId: Number(User?.id),
        moduleId: Number(moduleId),
        type: "lesson",
      };
      const progress: any = await POST(api, body);
      if (progress?.status) {
        setUserProgresses(progress?.data);
        let arr: any = [];
        progress?.data?.map((mod: any) => {
          arr.push(String(mod?.lessonId));
        });
        setProgressList(arr);

        let lsId = Course?.enrolement?.lessonId;
        const foundObject = await lessonList?.find(
          (obj: any) => obj?.id === String(lsId)
        );
        let newOrder = Number(foundObject?.order);
        const data = await lessonList?.find(
          (obj: any) => obj?.order === newOrder
        );

        setLoading2(false);
      } else {
        setUserProgresses([]);
        setLoading2(false);
      }
    } catch (error) {
      setLoading2(false);
      console.log(error);
    }
  };

  const getEnrolement = async () => {
    try {
      setLoading2(true);
      let api = "Enrollments/list";
      let obj = {
        userId: Number(User?.id),
        courseId: Number(showData?.courseId),
        page: 1,
        take: 10,
      };
      let enrolement: any = await POST(api, obj);
      if (enrolement?.status) {
        console.log(enrolement?.data[0]);
        setEnrollement(enrolement?.data[0]);
        dispatch(updateEnrolement(enrolement?.data[0]));
        setLoading2(false);
      } else {
        setEnrollement({});
        setLoading2(false);
      }
    } catch (error) {
      console.log(error);
      setLoading2(false);
    }
  };

  const onClickNext = async () => {
    try {
      setLoading2(true);
      console.log("showData ++++++ ", showData);
      let api = "lesson/nextLesson";
      let body = {
        lessonId: Number(Course?.enrolement?.lessonId),
        moduleId: Number(Course?.enrolement?.moduleId),
        userId: Number(User.id),
        courseId: Number(Course?.enrolement?.courseId),
        order: Number(showData?.order),
      };
      const nextLesson: any = await POST(api, body);
      if (nextLesson?.status) {
        dispatch(updateEnrolement(nextLesson?.data?.enrolement));
        getEnrolement();
        getProgress();
        loadData();
        setLoading2(false);
      }
    } catch (error) {
      setLoading2(false);
      console.log(error);
    }
  };

  console.log("showData --> ", showData);

  const handleGoBack = () => {
    navigate(-1);
  };

  const allLessonsCompleted = () => {
    return progressList.length === lessonList.length;
  };

  // const onClick = (data: any) => {
  //   if (!progressList.includes(String(data?.id))) {
  //     toast.error("This lesson is locked.");
  //     return;
  //   }
  //   setLoading2(true);
  //   setShowData(data);
  //   setLoading2(false);
  // };
  const onClick = (data: any) => {
    if (!progressList.includes(String(data?.id))) {
      notification.error({
        message: "Locked Lesson",
        description: "This lesson is locked.",
        placement: "topRight",
      });
      return;
    }
    setLoading2(true);
    setShowData(data);
    setLoading2(false);
  };

  return (
    <div>
      <div className="main-box">
      <div className="headerBox">
        <Header header="Lessons" />
        <br />
      </div>
        <Row>
          <Col>
            <Card >
              <h2>{showData?.title}</h2>
              <br />
              {loading2 ? (
                <LoadingBox />
              ) : (
                <YouTube videoId={showData?.video} opts={{ width: "100%" }} />
              )}
              <br />
              <p>{showData?.description}</p>
              <br />

              {allLessonsCompleted() ? (
                <Button
                  onClick={() => navigate(`/Auth/quiz/${moduleId}`)}
                  style={{ margin: "10px 0", borderRadius: "20px" }}
                >
                  Quiz
                </Button>
              ) : (
                <>
                  {order === lessonList.length ? (
                    <Button
                      onClick={() => navigate(`/Auth/quiz/${moduleId}`)}
                      style={{ margin: "10px 0", borderRadius: "20px" }}
                    >
                      Quiz
                    </Button>
                  ) : (
                    <Button
                      onClick={() => onClickNext()}
                      style={{ margin: "10px 0", borderRadius: "20px" }}
                    >
                      Next Lesson
                    </Button>
                  )}
                </>
              )}
            </Card>
          </Col>
          <Col md="3">
            <Button onClick={()=>navigate(`/Auth/modules/${showData.courseId}`)}>Go to Modules</Button>
            <br />
            <br />
            <Card className="stickyBox">
              <div className="">All Lessons</div>
              {lessonList?.map((lesson: any) => {
                return (
                  <Card
                    key={lesson?.id}
                    loading={loading2}
                    onClick={() => onClick(lesson)}
                    className={`module-card ${
                      !progressList.includes(String(lesson?.id)) ? "locked" : ""
                    }`}
                  >
                    <Meta
                      avatar={
                        progressList.includes(String(lesson?.id)) ? (
                          <IoCheckmarkCircle size={25} color={"green"} />
                        ) : (
                          <CiLock size={25} color={"#000"} />
                        )
                      }
                      title={lesson?.title}
                      description={
                        <div className="">
                          {progressList.includes(String(lesson?.id))
                            ? "Unlocked"
                            : "Locked"}
                        </div>
                      }
                    />
                  </Card>
                );
              })}
            </Card>
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </div>
  );
};

export default LessonScreen;

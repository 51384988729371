import React, { useEffect, useState } from "react";
import { Table, notification, Select, Card, Pagination } from "antd";
import moment from "moment";
import Header from "../../components/header";
import { API } from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";
import LoadingBox from "../../components/loading";
import EmptyBox from "../../components/emptyBox";
import { Col, Row } from "react-bootstrap";

const { Option } = Select;

const Enrollments = () => {
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(40);
  const [meta, setMeta] = useState<any>({});
  const [enrollments, setEnrollments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState<string | null>(null);
  const [users, setUsers] = useState<any[]>([]);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);

  useEffect(() => {
    loadData(page, 10);
    loadCourseData();
    getUser();
  }, [page]);

  const loadData = async (page: number, take: number) => {
    setIsLoading(true);
    try {
      let url = `${API.GET_ENROLLMENTS}?order=DESC&page=${page}&take=${take}`;
      const response: any = await GET(url, {});
      if (response?.status) {
        const sortedData = response.data.sort((a: any, b: any) => a.id - b.id);
        setEnrollments(sortedData);
        setMeta(response?.meta);
      } else {
        setEnrollments([]);
      }
    } catch (err) {
      notification.error({ message: "Server error" });
    } finally {
      setIsLoading(false);
    }
  };

  const loadCourseData = async () => {
    setIsLoading(true);
    try {
      let url = `${API.GET_COURSES}?order=DESC&page=${page}&take=${take}`;
      const response: any = await GET(url, {});
      if (response?.status) {
        setCourseData(response?.data);
      } else {
        setCourseData([]);
      }
    } catch (err) {
      notification.error({ message: "Server error" });
    } finally {
      setIsLoading(false);
    }
  };

  const filterData = async (courseId: string | null, userId: string | null) => {
    setIsLoading(true);
    try {
      let url = API.LISTENROLLMENT;
      let obj = {
        courseId: courseId ? Number(courseId) : undefined,
        userId: userId ? Number(userId) : undefined,
        page: page,
        take: take,
      };
      const response: any = await POST(url, obj);
      if (response?.status) {
        setEnrollments(response?.data);
      } else {
        setEnrollments([]);
        notification.error({ message: "No data found" });
      }
    } catch (err) {
      notification.error({ message: "Server error" });
    } finally {
      setIsLoading(false);
    }
  };

  const getUser = async () => {
    setIsLoading(true);
    try {
      let api = `${API.GET}?order=DESC&page=${page}&take=${take}`;
      const response: any = await GET(api, {});
      if (response?.status) {
        setUsers(response?.data);
      } else {
        setUsers([]);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const pagination = (currentPage: number, pageSize: number) => {
    setPage(currentPage);
    loadData(currentPage, pageSize);
  };

  const handleCourseChange = (value: string) => {
    setSelectedCourse(value);
    filterData(value, selectedUser);
  };

  const handleUserChange = (value: string) => {
    setSelectedUser(value);
    filterData(selectedCourse, value);
  };

  const columns = [
    {
      title: "Enrollment Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "User Name",
      dataIndex: ["user", "username"],
      key: "username",
    },
    {
      title: "Course Title",
      dataIndex: ["course", "titleOne"],
      key: "courseTitle",
    },
    {
      title: "Enrollment Date",
      dataIndex: "enrollmentDate",
      key: "enrollmentDate",
      render: (date: string) => moment(date).format("DD-MM-YYYY"),
    },
  ];

  return (
    <div className="main-box">
      <Header
        header="Enrollments"
        btn={false}
        onBtnClick={() => console.log("hello")}
      />
      <br />

      <Card>
        <Row>
          <Col md="4">
            <Select
              onChange={handleCourseChange}
              style={{ width: "100%" }}
              size="large"
              value={selectedCourse}
              placeholder="Select a course"
            >
              {courseData?.map((course: any) => (
                <Option key={course?.id} value={course?.id}>
                  {course?.titleOne}
                </Option>
              ))}
            </Select>
          </Col>

          <Col md="4">
            <Select
              size="large"
              onChange={handleUserChange}
              style={{ width: "100%" }}
              value={selectedUser}
              placeholder="Select a user"
            >
              {users?.map((user: any) => (
                <Option key={user?.id} value={user?.id}>
                  {user?.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <br />

        {isLoading ? (
          <LoadingBox />
        ) : enrollments.length > 0 ? (
          <>
            <h4 className="">
              Showing {enrollments?.length} of {meta?.itemCount} Enrollments
            </h4>
            <br />

            <Table
              scroll={{ x: 100 }}
              dataSource={enrollments}
              columns={columns}
              pagination={false}
              rowKey="id"
            />
          </>
        ) : (
          <EmptyBox />
        )}
        <br />
        <div className="paginationBox">
          <Pagination
            current={page}
            pageSize={meta.take}
            total={meta.itemCount}
            onChange={pagination}
          />
        </div>
      </Card>
    </div>
  );
};

export default Enrollments;

import React, { forwardRef } from "react";
import Logos from "../modules/image/logo.png";
import { useSelector } from "react-redux";

const HtmlContent = forwardRef((props: any, ref: any) => {
  const User: any = useSelector((val: any) => val.User.user);
  console.log("download --> ", props?.module);

  return (
    <div ref={ref} style={{ padding: 20, background: "#f5f5f5" }}>
      {/* <div ref={divRef} style={{ padding: 20, background: "#f5f5f5" }}> */}
      <div className="certificate-container">
        <div className="certificate-header">
          <div className="sideBar-logo-toggle">
            <img alt="E-Learn" src={Logos} className="small-logos" />
          </div>
          <br />
          <h1 className="certificate-title">Certificate of Completion</h1>
        </div>
        <div className="certificate-content">
          <p>This certifies that</p>
          <h2>{User.name}</h2>
          <p>has successfully completed the course</p>
          {Array.from(
            new Set(props?.modules?.map((cat: any) => cat?.course?.titleOne))
          ).map((title: any, index: any) => (
            <h2 key={index}>{title}</h2>
          ))}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
});

export default HtmlContent;

import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  notification,
  Card,
  Row,
  Col,
  DatePicker,
  Pagination,
} from "antd";
import Header from "../../components/header";
import { API } from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";

import { useNavigate, useParams } from "react-router-dom";

import ModuleDataTable from "./datatables";

import LoadingBox from "../../components/loading";
import EmptyBox from "../../components/emptyBox";

const Modulecreation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { courseId } = useParams();
  const [data, setData] = useState<any>([]);
  const [moduleData, setModuleData] = useState([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});

  console.log("asdf----------", moduleData);

  useEffect(() => {
    loadData(page, 6);
  }, [page]);

  const reload = () => {
    loadData(1, 6);
  };

  const loadData = async (page: any, take: any) => {
    setIsLoading(true);
    try {
      let url = `${
        API.GET_COURSE_MODULE + Number(courseId)
      }?order=ASC&page=${page}&take=${take}`;
      const response: any = await GET(url, {});
      if (response?.status) {
        setModuleData(response?.data);
        setMeta(response?.meta);
        setIsLoading(false);
      } else {
        setModuleData([]);
        setIsLoading(false);
        notification.error({ message: "No data found" });
      }
    } catch (err) {
      setIsLoading(false);
      notification.error({ message: "Server error" });
    }
  };

  const pagination = (currentPage: any, pageSize: any) => {
    setPage(currentPage);
    loadData(currentPage, 6);
  };

  const handleFormSubmit = async (valuess: any) => {
    try {
      const values = await form.validateFields();
      setIsLoading(true);
      const obj = {
        titleOne: values.titleOne,
        titleTwo: values.titleTwo,
        description: values.description,
        courseId: Number(courseId),
        order: 1,
      };
      console.log("Form values:", obj);
      const api = API.POST_MODULES;

      const postRequest: any = await POST(api, obj);
      console.log("API response:", postRequest);
      if (postRequest.status) {
        notification.success({ message: "Modulecreated" });
        form.resetFields();
        setModalVisible(false);
      } else {
        notification.error({ message: "Failed to create course" });
      }
    } catch (error) {
      console.error("Form validation failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddModule = () => {
    console.log("Add module button clicked");
  };

  return (
    <div className="main-box">
      <Header header="Modules" btn onBtnClick={() => setModalVisible(true)} />
      <br />

      {/* {isLoading ? (
        <LoadingBox />
     ? ( */}
      <>
        <ModuleDataTable data={moduleData} />
        <br />
        <Pagination
          current={page}
          pageSize={meta.take}
          total={meta.itemCount}
          onChange={pagination}
        />
      </>
      {/* ) : (
        <EmptyBox />
      )} */}

      <Modal
        title="Create New Module"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            size="large"
            key="cancel"
            onClick={() => setModalVisible(false)}
          >
            Cancel
          </Button>,
          <Button
            size="large"
            key="submit"
            type="primary"
            onClick={handleFormSubmit}
            loading={isLoading}
          >
            Submit
          </Button>,
        ]}
        width={800}
      >
        <Form form={form} layout="vertical">
          <label className="formLabel">TitleOne</label>
          <Form.Item
            name="titleOne"
            style={{ marginBottom: 10 }}
            rules={[{ required: true, message: "Please enter title one" }]}
          >
            <Input style={{ width: "100%" }} size="large" placeholder="" />
          </Form.Item>
          <label className="formLabel">TitleTwo</label>
          <Form.Item
            name="titleTwo"
            style={{ marginBottom: 10 }}
            rules={[{ required: true, message: "Please enter title two" }]}
          >
            <Input style={{ width: "100%" }} size="large" placeholder="" />
          </Form.Item>
          <label className="formLabel">Description</label>
          <Form.Item
            name="description"
            rules={[{ required: true, message: "Please enter description" }]}
          >
            <Input.TextArea size="large" placeholder="" rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Modulecreation;

import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { GET } from "../../utils/apiCalls";
import { API } from "../../config/api";
import DataTable from "../dashboard/Table";
import { Card, Pagination, notification } from "antd";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import LoadingBox from "../../components/loading";
import EmptyBox from "../../components/emptyBox";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataCount, setDataCount] = useState<any>({});
  const [Users, setUsers] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});

  const User: any = useSelector((val: any) => val.User.user);

  const getUser = async (page: any, take: any) => {
    try {
      setIsLoading(true);
      let api = API.GET + `?order=ASC&page=${page}&take=${take}`;
      const count: any = await GET(api, {});
      if (count?.status) {
        setUsers(count?.data || []);
        setMeta({
          itemCount: count.meta.itemCount,
          take: take,
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setUsers([]);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const getAllData = async () => {
    setIsLoading(true);
    try {
      let api = API.GET_COUNT;
      const count: any = await GET(api, {});
      if (count?.status) {
        setDataCount(count?.data);
      } else {
        console.error("Failed to fetch data from the API.");
      }

      let enrollmentsApi = `${API.GET_ENROLLMENTS}?order=DESC&page=1&take=1`;
      const enrollmentsResponse: any = await GET(enrollmentsApi, {});
      if (enrollmentsResponse?.status) {
        setDataCount((prevDataCount: any) => ({
          ...prevDataCount,
          enrollments: enrollmentsResponse.meta.itemCount,
        }));
      } else {
        console.error("Failed to fetch enrollments count from the API.");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllData();
    getUser(page, 10);
  }, [page]);

  const navigate = useNavigate();

  const pagination = (currentPage: any, pageSize: any) => {
    setPage(currentPage);
    getUser(currentPage, pageSize);
  };

  return (
    <>
      <div className="main-box">
        <Row>
          <Col md="4">
            <Card
              className="user-card"
              onClick={() => navigate("/Admin/admin-usres")}
            >
              <Card.Meta title="Users" description={dataCount?.users} />
            </Card>
          </Col>

          <Col md="4">
            <Card
              className="user-card"
              onClick={() => navigate("/Admin/admin-courses")}
            >
              <Card.Meta title="Courses" description={dataCount?.course} />
            </Card>
          </Col>
          <Col md="4">
            <Card
              className="user-card"
              onClick={() => navigate("/Admin/admin-enrolements")}
            >
              <Card.Meta
                title="Enrollments"
                description={dataCount?.enrollments}
              />
            </Card>
          </Col>
        </Row>

        <br />

        <Card className="">
          {isLoading ? (
            <LoadingBox />
          ) : Users.length > 0 ? (
            
            <DataTable dataSource={Users} />
          ) : (
            <EmptyBox title="Users" />
          )}

          <br/>
          <div className="paginationBox">
          <Pagination
            current={page}
            pageSize={meta.take}
            total={meta.itemCount}
            onChange={pagination}
          />
        </div>
        </Card>
    </div>
    </>
  );
};

export default Dashboard;

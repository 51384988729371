import React from "react";

const DownloadHtml = () => {
  const downloadHtmlContent = () => {
    const url = "http://example.com"; // Replace with your URL

    fetch(url)
      .then((response) => response.text())
      .then((html) => {
        const element = document.createElement("a");
        const file = new Blob([html], { type: "text/html" });
        element.href = URL.createObjectURL(file);
        element.download = "downloaded.html"; // Desired file name
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      })
      .catch((error) => {
        console.error("Error fetching HTML:", error);
      });
  };

  return <button onClick={downloadHtmlContent}>Download HTML</button>;
};

export default DownloadHtml;

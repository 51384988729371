import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  notification,
  Card,
  Row,
  Col,
  DatePicker,
  Pagination,
} from "antd";
import Header from "../../components/header";
import { API } from "../../config/api";
import { GET, POST, PUT } from "../../utils/apiCalls";
import { useNavigate, useParams } from "react-router-dom";
import LessonDataTable from "./datatables";
import { log } from "console";

import DataTable from "./datatables";
import CreateModel from "./component/createmodel";
import EditModel from "./component/editmodel";
import LoadingBox from "../../components/loading";
import EmptyBox from "../../components/emptyBox";

const Lessoncreation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { moduleId, courseId } = useParams();
  const [data, setData] = useState<any>([]);
  const [lessonData, setLessonData] = useState([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    loadData(page, 6);
  }, [page]);

  const loadData = async (page: any, take: any) => {
    setIsLoading(true);
    try {
      let url = `${
        API.GET_COURSE_LESSON + Number(moduleId)
      }?order=ASC&page=${page}&take=${take}`;
      const response: any = await GET(url, {});
      if (response?.status) {
        setLessonData(response?.data);
        setMeta(response?.meta);
      } else {
        setLessonData([]);
        notification.error({ message: "No data found" });
      }
    } catch (err) {
      notification.error({ message: "Server error" });
    } finally {
      setIsLoading(false);
    }
  };

 
  const pagination = (currentPage: any, pageSize: any) => {
    setPage(currentPage);
    loadData(currentPage, 6);
  };

  const handleFormSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      const obj = {
        lessonId: values.lessonId,
        title: values.title,
        courseId: Number(courseId),
        moduleId: Number(moduleId),
        description: String(values.description),
        syntax: values.syntax,
        status: true,
        video: values.video,
      };

      console.log("Form values:", obj);
      const api = API.POST_LESSON;

      const postRequest: any = await POST(api, obj);
      console.log("API response:", postRequest);
      if (postRequest.status) {
        notification.success({ message: "Lesson created" });
        form.resetFields();
        setModalVisible(false);
        loadData(page, 6);
      } else {
        notification.error({ message: "Failed to create Lesson" });
      }
    } catch (error) {
      console.error("Form validation failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="main-box">
      <div className="main-txt">
        <Header header="Lessons" btn onBtnClick={() => setModalVisible(true)} />
        <br />
        <Button
          type="primary"
          size="large"
          style={{ width: "150px" }}
          onClick={() => navigate(`/Admin/admin-quiz/${courseId}/${moduleId}`)}
        >
          Quiz
        </Button>
        <br />
        <br />
        {isLoading ? (
          <LoadingBox />
        ) : lessonData.length > 0 ? (
          <DataTable dataSource={lessonData} />
        ) : (
          <EmptyBox />
        )}
        <br />
        <Pagination
          current={page}
          pageSize={meta.take}
          total={meta.itemCount}
          onChange={pagination}
        />
      </div>
      <CreateModel
        data={lessonData}
        open={modalVisible}
        close={() => setModalVisible(false)}
        handleFormSubmit={handleFormSubmit}
      />
    </div>
  );
};

export default Lessoncreation;

// import React from "react";

// const DownloadButton = ({ contentRef }: any) => {
//   const handleDownloadHtml = () => {
//     const htmlContent = contentRef.current.innerHTML;
//     const completeHtmlContent = `
//       <!DOCTYPE html>
//       <html>
//         <head>
//           <title>Downloaded HTML</title>
//         </head>
//         <body>
//           ${htmlContent}
//         </body>
//       </html>
//     `;

//     const blob = new Blob([completeHtmlContent], { type: "text/html" });
//     const link = document.createElement("a");
//     link.href = URL.createObjectURL(blob);
//     link.download = "download.html";
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
//   };

//   return <button onClick={handleDownloadHtml}>Download HTML</button>;
// };

// export default DownloadButton;

import React from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Button } from "antd";
import { MdDownload } from "react-icons/md";

const DownloadButton = ({ contentRef }: any) => {
  const handleDownloadPdf = () => {
    const divContent = contentRef.current;

    html2canvas(divContent).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };

  return (
    <div
      className=""
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <br />
      <br />
      <br />
      <Button onClick={handleDownloadPdf}>
        Download &nbsp; <MdDownload size={20} />
      </Button>
    </div>
  );
};

export default DownloadButton;

import React, { useState } from "react";
import { Table, Button, notification, Space, Modal } from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import EditModel from "./component/editmodel";
import { API } from "../../config/api";
import { PUT } from "../../utils/apiCalls";
import { useParams } from "react-router-dom";

interface Lesson {
  lessonId: number;
  title: string;
  id: number;
}

interface DataTableProps {
  dataSource: Lesson[] | null;
}

const DataTable: React.FC<DataTableProps> = ({ dataSource }) => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState<Lesson | null>(null);
  const { moduleId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [viewingCourse, setViewingCourse] = useState<any>(null);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);

  const handleEditClick = (record: Lesson) => {
    setSelectedLesson(record);
    setEditModalVisible(true);
  };

  const handleEditLesson = async (values: any) => {
    try {
      setIsLoading(true);
      const obj = {
        title: values.title,
        moduleId: Number(moduleId),
        description: values.description,
        syntax: values.syntax,
        video: values.video,
        status: true,
      };

      const api = API.UPDATE_LESSON + selectedLesson?.id;
      const updateRequest: any = await PUT(api, obj);
      console.log("API response:", updateRequest);
      if (updateRequest.status) {
        notification.success({ message: "Lesson updated" });
        setEditModalVisible(false);
       
      } else {
        notification.error({ message: "Failed to update Lesson" });
      }
    } catch (error) {
      console.error("Form validation failed:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const showViewModal = (course: any) => {
    setViewingCourse(course);
    setIsViewModalVisible(true);
  };

  const handleViewCancel = () => {
    setIsViewModalVisible(false);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "View/Edit",
      dataIndex: "id",
      key: "id",
      render: (_: any, record: Lesson) => (
        <Space size="middle">
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEditClick(record)}
          />
          <EyeOutlined
            style={{ cursor: "pointer" }}
            onClick={() => showViewModal(record)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      {dataSource ? (
        <Table columns={columns} dataSource={dataSource} pagination={false} />
      ) : (
        <p>No lessons available</p>
      )}

      <EditModel
        data={selectedLesson}
        open={editModalVisible}
        handleEditLesson={handleEditLesson}
        close={() => setEditModalVisible(false)}
      />
      {viewingCourse && (
        <Modal
          title="View Course"
          visible={isViewModalVisible}
          onCancel={handleViewCancel}
          width={700}
          bodyStyle={{ maxHeight: "1000vh", overflowY: "auto" }}
          footer={[
            <Button key="close" onClick={handleViewCancel}>
              Close
            </Button>,
          ]}
        >
          <p>
            <strong>Title:</strong> {viewingCourse.title}
          </p>
          <p>
            <strong>Description:</strong> {viewingCourse.description}
          </p>
          <p>
            <strong>Syntax:</strong> {viewingCourse.syntax}
          </p>
          <p>
            <strong>Video :</strong> {viewingCourse.video}
          </p>
          <p>
            <strong>Order:</strong> {viewingCourse.order}
          </p>
        </Modal>
      )}
    </div>
  );
};

export default DataTable;

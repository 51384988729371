
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, notification, Card, Row, Col, Pagination } from "antd";
import Header from "../../components/header";
import { API } from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";
import { CiSearch } from "react-icons/ci";
import CourseCategoryDataTable from "./dataTable";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import EmptyBox from "../../components/emptyBox";
import LoadingBox from "../../components/loading";

const Category = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState<any>([]);
  const [searchResult, setSearchResult] = useState([]);
  const [courseCategoryData, setCourseCategoryData] = useState([]);
  const [searchResultCount, setSearchResultCount] = useState(0);
  const { category } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const [isLoading2, setIsLoading2] = useState(false);
  const reload = () => {
    loadData(1, 10);
  };

  useEffect(() => {
    loadData(page, 12);
  }, [page]);

  const loadData = async (page: number, take: number) => {
    setIsLoading(true);
    try {
      let url = API.GET_COURSE_CATEGORY + `?order=ASC&page=${page}&take=${take}`;
      const courseCategory: any = await GET(url, {});
      setCourseCategoryData(courseCategory?.data || []);
      setMeta({
        itemCount: courseCategory.meta.itemCount,
        take: take,
      });
    } catch (err) {
      console.error("Error while loading data:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const loadcategoryData = async () => {
    setIsLoading(true);
    try {
      let url = API.GET_COURSE_LIST + category;
      const course: any = await POST(url, {});
      setCourseCategoryData(course?.data || []);
    } catch (error) {
      console.error("Error while loading category data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormSubmit = async () => {
    try {
      const values = await form.validateFields();
      setIsLoading(true);
      const obj = {
        categoryId: values.categoryId,
        type: values.type,
      };
      const api = API.POST_CATEGORY;
      const postRequest: any = await POST(api, obj);
      if (postRequest.status) {
        notification.success({ message: "Category created" });
        form.resetFields();
        setModalVisible(false);

        reload();
      } else {
        notification.error({ message: "Failed to create category" });
      }
    } catch (error) {
      console.error("Form validation failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const search = async (query: string) => {
    setIsLoading2(true);
    try {
      let url = API.SEARCH_CATEGORY;
      let obj = { type: query };
      const response: any = await POST(url, obj);
      setIsLoading2(false);
      if (response?.status) {
        setCourseCategoryData(response?.data);
        setSearchResultCount(response?.meta.itemCount);
      } else {
        setCourseCategoryData([]);
        setSearchResultCount(0);
        notification.error({ message: "No data found" });
      }
    } catch (err) {
      setIsLoading2(true);
    }
  };

  const pagination = (currentPage: number, pageSize: number) => {
    setPage(currentPage);
    loadData(currentPage, pageSize);
  };



  return (

    <div className="main-box">
      <Header
        header="Categories"
        btn
        onBtnClick={() => setModalVisible(true)}
      />
      <br />
      <Card>
        {isLoading ? (
          <LoadingBox />
        ) : courseCategoryData.length > 0 ? (
          <>
            <h4 className="">
              Showing {courseCategoryData?.length} of {meta?.itemCount} Categories
            </h4>
            <br />

            <Input
              bordered
              style={{ width: "300px" }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                search(e.target.value)
              }
              suffix={<CiSearch size={16} color="#000" />}
              placeholder="Search for users"
            />
            <br />
            <br />

            <CourseCategoryDataTable data={courseCategoryData} />
          </>
        ) : (
          <EmptyBox title="Categories" />
        )}

        <Modal
          title="Create New Category"
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={[
            <Button
              size="large"
              key="cancel"
              onClick={() => setModalVisible(false)}
            >
              Cancel
            </Button>,
            <Button
              size="large"
              key="submit"
              type="primary"
              onClick={handleFormSubmit}
              loading={isLoading}
            >
              Submit
            </Button>,
          ]}
          width={400}
        >
          <Form form={form} layout="vertical">
            <br />
            {/* <label className="formLabel">Category ID</label> */}
            {/* <Form.Item
            name="categoryId"
            style={{ marginBottom: 10 }}
            rules={[{ required: true, message: "Please enter category id" }]}
          >
            <Input size="large" placeholder="" />
          </Form.Item> */}
            <label className="formLabel">Type</label>
            <Form.Item
              name="type"
              style={{ marginBottom: 10 }}
              rules={[{ required: true, message: "Please enter category" }]}
            >
              <Input style={{ width: "100%" }} size="large" placeholder="Type" />
            </Form.Item>
          </Form>
        </Modal>



        <div className="paginationBox">
          <Pagination
            current={page}
            pageSize={meta.take}
            total={meta.itemCount}
            onChange={pagination}
          />
        </div>


      </Card>
    </div>
  );
};

export default Category;

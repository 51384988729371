// import {
//   Button,
//   Card,
//   DatePicker,
//   Form,
//   Input,
//   Upload,
//   notification,
// } from "antd";
// import React, { useEffect, useState } from "react";
// import { Col, Container, Row } from "react-bootstrap";
// import dayjs from "dayjs";
// import {
//   EditOutlined,
//   EyeInvisibleOutlined,
//   EyeOutlined,
// } from "@ant-design/icons";
// import {  GET, PUT } from "../../utils/apiCalls";
// import { useSelector } from "react-redux";
// import { API } from "../../config/api";

// interface User {
//   id: string;
//   name: string;
//   email: string;
//   dob: string;
//   registrationDate: string;
//   phone: string;

// }

// function Profile() {

//   const [isLoading, setIsLoading] = useState(false);
//   const [isLoading2, setIsLoading2] = useState(false);

//   const User = useSelector((val: any) => val.User.user);
//   const [userData, setUserData] = useState<User | null>(null);

//   const [form] = Form.useForm();

//   useEffect(() => {
//     getUserData();
//   }, []);

//   useEffect(() => {
//     if (userData) {
//       form.setFieldsValue({
//         name: userData?.name,
//         email: userData?.email,
//         dob: userData?.dob ? dayjs(userData.dob) : null,
//         phone: userData?.phone,
//       });
//     }
//   }, [userData, form]);

//   const getUserData = async () => {
//     try {
//       setIsLoading(true);
//       const url = API.GET + User.id;
//       const { data }: any = await GET(url, null);
//       setUserData(data);
//       console.log(data);
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const submitHandler = async (values: any) => {
//     try {
//       let obj: {
//         name: string;
//         email: string;
//         dob: string;
//         phone: string;

//         password?: string;
//       } = {
//         name: values.name,
//         email: values.email,
//         dob: values.dob.format("YYYY-MM-DD"),
//         phone: values.phone,

//       };

//       if (values.password) {
//         obj.password = values.password;
//       }

//       setIsLoading2(true);
//       const api = API.UPDATE + User.id;
//       const postRequest: any = await PUT(api, obj);

//       if (postRequest.status) {
//         notification.success({ message: "User Updated" });
//       } else {
//         notification.error({ message: "Failed to Update" });
//       }
//     } catch (error) {
//       console.log(error);
//       notification.error({
//         message: "Something went wrong! Please try again later",
//       });
//     } finally {
//       setIsLoading2(false);
//     }
//   };

//   const submitHandler = async (values: any) => {
//     try {
//       let obj = {
//         name: values.name,
//         email: values.email,
//         dob: values.dob.format("YYYY-MM-DD"),
//         phone: values.phone,
//         image: String(file),
//       };
//       setIsLoading2(true);
//       let api = API.UPDATE + User.id;
//       let postRequest: any = await PUT(api, obj);
//       if (postRequest.status) {
//         notification.success({ message: "User Updated" });
//       } else {
//         notification.error({ message: "Failed to Update" });
//       }
//     } catch (error) {
//       console.log(error);
//       notification.error({
//         message: "Something went wrong! Please try again later",
//       });
//     } finally {
//       setIsLoading2(false);
//     }
//   };

//   return (
//     <Container>
//       <Form form={form} onFinish={submitHandler}>
//         <Card>
//           <Row>
//             <Col md="6">

//             </Col>

//             <Col md="6" />
//             <br />
//             <br />
//             <Col md="6">
//               <label className="formLabel">Name</label>
//               <Form.Item
//                 name="name"
//                 style={{ marginBottom: 10 }}
//                 rules={[{ required: true }]}
//               >
//                 <Input size="large" placeholder="" />
//               </Form.Item>
//             </Col>

//             <Col md="6">
//               <label className="formLabel">Email</label>
//               <Form.Item
//                 name="email"
//                 style={{ marginBottom: 10 }}
//                 rules={[{ required: true }]}
//               >
//                 <Input size="large" placeholder="" />
//               </Form.Item>
//             </Col>
//             <Col md="6">
//               <label className="formLabel">DOB</label>
//               <Form.Item
//                 name="dob"
//                 style={{ marginBottom: 10 }}
//                 rules={[{ required: true }]}
//               >
//                 <DatePicker
//                   placeholder=""
//                   size="large"
//                   style={{ width: "100%" }}
//                 />
//               </Form.Item>
//             </Col>

//             <Col md="6">
//               <label className="formLabel">Phone Number</label>
//               <Form.Item
//                 name="phone"
//                 style={{ marginBottom: 10 }}
//                 rules={[{ required: true }]}
//               >
//                 <Input size="large" placeholder="" />
//               </Form.Item>
//             </Col>
//             <Col md="6">
//               <label className="formLabel">Password</label>
//               <Form.Item
//                 name="password"
//                 style={{ marginBottom: 10 }}
//                 rules={[{ required: false }]}
//               >
//                 <Input.Password
//                   size="large"
//                   placeholder=""
//                   iconRender={(visible) =>
//                     visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
//                   }
//                 />
//               </Form.Item>
//             </Col>
//           </Row>
//           <br />
//           <Row>
//             <Col md="6"></Col>
//             <Col md="3">
//               <Form.Item>
//                 <Button size="large" style={{ width: "100%" }}>
//                   Cancel
//                 </Button>
//               </Form.Item>
//             </Col>
//             <Col md="3">
//               <Form.Item>
//                 <Button
//                   size="large"
//                   style={{ width: "100%" }}
//                   type="primary"
//                   htmlType="submit"
//                   loading={isLoading}
//                 >
//                   Update
//                 </Button>
//               </Form.Item>
//             </Col>
//           </Row>
//         </Card>
//       </Form>
//     </Container>
//   );
// }

// export default Profile;

import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  notification,
  Card,
  Upload,
  DatePicker,
} from "antd";
import { Col, Container, Row } from "react-bootstrap";
import dayjs from "dayjs";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { GET, PUT } from "../../utils/apiCalls";
import { useSelector } from "react-redux";
import { API } from "../../config/api";

interface User {
  id: string;
  name: string;
  email: string;
  dob: string;
  phone: string;
}

function Profile() {
  const [isLoading, setIsLoading] = useState(false);
  const User = useSelector((val: any) => val.User.user);
  const [userData, setUserData] = useState<User | null>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        name: userData?.name,
        email: userData?.email,
        dob: userData?.dob ? dayjs(userData.dob) : null,
        phone: userData?.phone,
        password: "",
      });
    }
  }, [userData, form]);

  const getUserData = async () => {
    try {
      setIsLoading(true);
      const url = API.GET + User.id;
      const { data }: any = await GET(url, null);
      setUserData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const submitHandler = async (values: any) => {
    try {
      const obj: {
        name: string;
        email: string;
        dob: string;
        phone: string;
        password?: string;
      } = {
        name: values.name,
        email: values.email,
        dob: values.dob.format("YYYY-MM-DD"),
        phone: values.phone,
      };

      if (values.password) {
        obj.password = values.password;
      }

      setIsLoading(true);
      const api = API.UPDATE + User.id;
      const postRequest: any = await PUT(api, obj);

      if (postRequest.status) {
        notification.success({ message: "User Updated" });
      } else {
        notification.error({ message: "Failed to Update" });
      }
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong! Please try again later",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <br />

      <Form form={form} onFinish={submitHandler}>
        <Card>
          <Row>
            <Col md="6">
              <label className="formLabel">Name</label>
              <Form.Item
                name="name"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>

            <Col md="6">
              <label className="formLabel">Email</label>
              <Form.Item
                name="email"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>
            <Col md="6">
              <label className="formLabel">DOB</label>
              <Form.Item
                name="dob"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <DatePicker
                  placeholder=""
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col md="6">
              <label className="formLabel">Phone Number</label>
              <Form.Item
                name="phone"
                style={{ marginBottom: 10 }}
                rules={[{ required: true }]}
              >
                <Input size="large" placeholder="" />
              </Form.Item>
            </Col>
            {/** Render password field only if the user intends to change their password */}
            {userData && (
              <Col md="6">
                <label className="formLabel">Password</label>
                <Form.Item
                  name="password"
                  style={{ marginBottom: 10 }}
                  rules={[{ required: false }]}
                >
                  <Input.Password
                    size="large"
                    placeholder=""
                    iconRender={(visible) =>
                      visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <br />
          <Row>
            <Col md="6"></Col>
            <Col md="3">
              <Form.Item>
                <Button
                  size="large"
                  style={{ width: "100%" }}
                  onClick={() => form.resetFields()}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Col md="3">
              <Form.Item>
                <Button
                  size="large"
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  Update
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Container>
  );
}

export default Profile;

import React, { useEffect, useState } from "react";
import { Button, Card, Input, Pagination, Spin, notification } from "antd";
import { Col, Row } from "react-bootstrap";
import { API } from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";
import LoadingBox from "../../components/loading";
import EmptyBox from "../../components/emptyBox";
import "./styles.scss";
import UserHeader from "../../components/userHeader";
import { CiSearch } from "react-icons/ci";
import CourseDataTables from "../../components/coursedesigin";
import Header from "../../components/header";
import { useNavigate } from "react-router-dom";

const Search = () => {
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<any>({});
  const [courses, setCourses] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [searchResultCount, setSearchResultCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadData(page, 6);
  }, [page]);

  const loadData = async (page: any, take: any) => {
    setIsLoading(true);
    try {
      let url = API.GET_COURSES + `?order=ASC&page=${page}&take=${take}`;
      const response: any = await GET(url, {});
      if (response?.status) {
        setCourses(response?.data);
        setMeta(response?.meta);
        setIsLoading(false);
      } else {
        setCourses([]);
        setIsLoading(false);
        notification.error({ message: "No data found" });
      }
    } catch (err) {
      setIsLoading(false);
      notification.error({ message: "Server error" });
    }
  };

  const search = async (query: string) => {
    setIsLoading2(true);
    try {
      let url = API.SEARCH_COURSES;
      let obj = { titleOne: query };

      const response: any = await POST(url, obj);
      if (response?.status) {
        setCourses(response?.data);
        setSearchResultCount(response?.meta);
        setIsLoading2(false);
      } else {
        setCourses([]);
        setSearchResultCount(0);
        notification.error({ message: "No data found" });
      }
    } catch (err) {
      setIsLoading2(false);
      notification.error({ message: "Server error" });
    }
  };

  const pagination = (currentPage: any, pageSize: any) => {
    setPage(currentPage);
    loadData(currentPage, 6);
  };

  return (
    <div className="main-box">
      <div className="main-box">
        <div className="headerBox">
          <Header header="Search" />
          <br />
        </div>

        <div className="">
          <Card
            extra={
              <div className="">
                <br />
                <Input
                  allowClear
                  style={{ width: "300px", marginBottom: "20px" }}
                  size="large"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    search(e.target.value)
                  }
                  placeholder="Search for courses"
                  suffix={isLoading2 ? <Spin /> : ""}
                />
              </div>
            }
          >
            {isLoading ? (
              <LoadingBox />
            ) : courses?.length ? (
              <div>
                <Row>
                  {courses?.map((cat: any) => {
                    return (
                      <Col md="3">
                        <Card
                          className="cat-card"
                          onClick={() => navigate(`/Auth/details/${cat?.id}`)}
                        >
                          <div className="txt1">
                            <img
                              src={cat.image}
                              alt="image"
                              style={{
                                backgroundColor: "red",
                                width: "100%",
                                height: "150px",
                                objectFit: "cover",
                              }}
                            />
                          </div>

                          <p className="card-titleone">{cat.titleOne}</p>
                          <p className="card-titletwo">{cat.titleTwo}</p>
                        </Card>
                        <br />
                      </Col>
                    );
                  })}
                </Row>
              </div>
            ) : (
              <EmptyBox title={"search"} />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Search;

import { Button, Card, Modal } from "antd";
import React, { useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./styles.scss";
import { IoArrowBackOutline } from "react-icons/io5";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { MdDownload } from "react-icons/md";
import DownloadButton from "./downloadButton";
import HtmlContent from "./htmlConrtent";

const Header = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const contentRef: any = useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleBackButtonClick = () => {
    if (location.pathname === "/Admin/admin-enrolements") {
      navigate("/Admin");
    } else if (location.pathname === "/Admin/admin-courses") {
      navigate("/Admin");
      navigate("/Admin/admin-dashboard");
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="header">
      <div className="headerBox">
        <Row>
          <Col md="1">
            <div className="headerBackBtn" onClick={handleBackButtonClick}>
              <IoArrowBackOutline color="grey" size={40} />
            </div>
          </Col>
          <Col md="3">
            <div className="headerHeadBox">
              <div className="headerTxt1">{props?.header}</div>
              <div className="headerTxt2">{props.header2}</div>
            </div>
          </Col>

          <Col md="6"></Col>
          <Col md="2">
            {props?.btn ? (
              <div className="headerButton" onClick={() => showModal()}>
                Certificate &nbsp; <MdDownload size={20} />
              </div>
            ) : null}
          </Col>
        </Row>
      </div>

      <Modal
        width={800}
        title="Download Certificate"
        open={isModalOpen}
        footer={false}
        onCancel={handleCancel}
      >
        <HtmlContent modules={props?.modules} ref={contentRef} />
        <DownloadButton contentRef={contentRef} />
      </Modal>
    </div>
  );
};

export default Header;

// import React, { useState } from "react";
// import { Table, Button, Modal, Input, Form, notification } from "antd";
// import { EditOutlined, EyeOutlined } from "@ant-design/icons";
// import { useNavigate } from "react-router-dom";
// import { API } from "../../config/api";
// import { PUT } from "../../utils/apiCalls";
// import LoadingBox from "../../components/loading";
// import EmptyBox from "../../components/emptyBox";

// function ModuleDataTable(props: any) {
//   console.log("props", props);
//   const { data, loadData } = props;
//   const navigate = useNavigate();
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [selectedModule, setSelectedModule] = useState<any>(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isLoading2, setIsLoading2] = useState(false);
//   const [viewingModule, setViewingModule] = useState<any>(null);
//   const [isViewModalVisible, setIsViewModalVisible] = useState(false);
//   const [editingModule, setEditingModule] = useState<any>(null);

//   const [form] = Form.useForm();

//   const showEditModal = (module: any) => {
//     setSelectedModule(module);
//     form.setFieldsValue(module);
//     setIsModalVisible(true);
//   };

//   const showViewModal = (module: any) => {
//     setViewingModule(module);
//     setIsModalVisible(true);
//   };

//   const handleOk = async () => {
//     try {
//       const values = await form.validateFields();
//       if (selectedModule?.id) {
//         const api = `${API.UPDATE_MODULE}${selectedModule.id}`;
//         setIsLoading2(false);
//         const updateRequest: any = await PUT(api, values);
//         console.log("API response:", updateRequest);

//         if (updateRequest.status) {
//           notification.success({ message: "Module updated" });
//           form.resetFields();
//           setIsModalVisible(false);
//           loadData();
//         } else {
//           notification.error({ message: "Failed to update module" });
//         }
//       } else {
//         notification.error({ message: "Module ID is missing" });
//       }
//     } catch (error) {
//       console.error("Form validation failed:", error);
//     } finally {
//       setIsLoading2(true);
//     }
//   };

//   const handleCancel = () => {
//     setIsModalVisible(false);
//   };

//   const handleViewCancel = () => {
//     setIsViewModalVisible(false);
//   };

//   const columns = [
//     {
//       title: "Title",
//       dataIndex: "titleOne",
//       key: "titleOne",
//       render: (text: string, record: any) => (
//         <a
//           onClick={() =>
//             navigate(`/Admin/admin-addLesson/${record.courseId}/${record.id}`)
//           }
//           style={{ color: "Blue" }}
//         >
//           {text}
//         </a>
//       ),
//     },
//     {
//       title: "Actions",
//       key: "actions",
//       render: (text: string, record: { id: any }) => (
//         <>
//           <Button
//             icon={<EyeOutlined />}
//             onClick={() => showViewModal(record)}
//             style={{ marginRight: 8 }}
//           />
//           <Button
//             icon={<EditOutlined />}
//             onClick={() => showEditModal(record)}
//           />
//         </>
//       ),
//     },
//   ];

//   return (
//     <>
//       <Table
//         columns={columns}
//         dataSource={props?.data?.rows}
//         rowKey="id"
//         pagination={false}
//         bordered
//       />

//       {editingModule && (
//         <Modal
//           title="Edit Course"
//           visible={isModalVisible}
//           onOk={handleOk}
//           onCancel={handleCancel}
//           width={900}
//           footer={[
//             <Button key="back" onClick={handleCancel}>
//               Cancel
//             </Button>,
//             <Button
//               key="submit"
//               type="primary"
//               onClick={handleOk}
//               loading={isLoading}
//             >
//               Save
//             </Button>,
//           ]}
//         >
//           <Form form={form} layout="vertical">
//             <Form.Item
//               label="Title One"
//               name="titleOne"
//               rules={[
//                 { required: true, message: "Please input the title one!" },
//               ]}
//             >
//               <Input />
//             </Form.Item>
//             <Form.Item
//               label="Title Two"
//               name="titleTwo"
//               rules={[
//                 { required: true, message: "Please input the title two!" },
//               ]}
//             >
//               <Input />
//             </Form.Item>
//             <Form.Item
//               label="Description"
//               name="description"
//               rules={[
//                 { required: true, message: "Please input the description!" },
//               ]}
//             >
//               <Input.TextArea />
//             </Form.Item>
//           </Form>
//         </Modal>
//       )}

//       {viewingModule && (
//         <Modal
//           title="View Module"
//           visible={isViewModalVisible}
//           onCancel={handleViewCancel}
//           width={900}
//           footer={[
//             <Button key="close" onClick={handleViewCancel}>
//               Close
//             </Button>,
//           ]}
//         >
//           <p>
//             <strong>Title One:</strong> {viewingModule.titleOne}
//           </p>
//           <p>
//             <strong>Title Two:</strong> {viewingModule.titleTwo}
//           </p>
//           <p>
//             <strong>Description:</strong> {viewingModule.description}
//           </p>
//         </Modal>
//       )}
//     </>
//   );
// }

// export default ModuleDataTable;
// import React, { useState } from "react";
// import { Table, Button, Modal, Input, Form, notification } from "antd";
// import { EditOutlined, EyeOutlined } from "@ant-design/icons";
// import { useNavigate } from "react-router-dom";
// import { API } from "../../config/api";
// import { PUT } from "../../utils/apiCalls";
// import LoadingBox from "../../components/loading";
// import EmptyBox from "../../components/emptyBox";

// function ModuleDataTable(props: any) {
//   console.log("props", props);
//   const { data, loadData } = props;
//   const navigate = useNavigate();
//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const [selectedModule, setSelectedModule] = useState<any>(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isLoading2, setIsLoading2] = useState(false);
//   const [viewingModule, setViewingModule] = useState<any>(null);
//   const [isViewModalVisible, setIsViewModalVisible] = useState(false);
//   const [editingModule, setEditingModule] = useState<any>(null);

//   const [form] = Form.useForm();

//   const showEditModal = (module: any) => {
//     setSelectedModule(module);
//     form.setFieldsValue(module);
//     setIsModalVisible(true);
//   };

//   const showViewModal = (module: any) => {
//     setViewingModule(module);
//     setIsViewModalVisible(true);
//   };

//   const handleOk = async () => {
//     try {
//       const values = await form.validateFields();
//       if (selectedModule?.id) {
//         const api = `${API.UPDATE_MODULE}${selectedModule.id}`;
//         setIsLoading2(false);
//         const updateRequest: any = await PUT(api, values);
//         console.log("API response:", updateRequest);

//         if (updateRequest.status) {
//           notification.success({ message: "Module updated" });
//           form.resetFields();
//           setIsModalVisible(false);
//           loadData();
//         } else {
//           notification.error({ message: "Failed to update module" });
//         }
//       } else {
//         notification.error({ message: "Module ID is missing" });
//       }
//     } catch (error) {
//       console.error("Form validation failed:", error);
//     } finally {
//       setIsLoading2(true);
//     }
//   };

//   const handleCancel = () => {
//     setIsModalVisible(false);
//   };

//   const handleViewCancel = () => {
//     setIsViewModalVisible(false);
//   };

//   const columns = [
//     {
//       title: "Title",
//       dataIndex: "titleOne",
//       key: "titleOne",
//       render: (text: string, record: any) => (
//         <a
//           onClick={() =>
//             navigate(`/Admin/admin-addLesson/${record.courseId}/${record.id}`)
//           }
//           style={{ color: "Blue" }}
//         >
//           {text}
//         </a>
//       ),
//     },
//     {
//       title: "Actions",
//       key: "actions",
//       render: (text: string, record: { id: any }) => (
//         <>
//           <Button
//             icon={<EyeOutlined />}
//             onClick={() => showViewModal(record)}
//             style={{ marginRight: 8 }}
//           />
//           <Button
//             icon={<EditOutlined />}
//             onClick={() => showEditModal(record)}
//           />
//         </>
//       ),
//     },
//   ];

//   return (
//     <>
//       <Table
//         columns={columns}
//         dataSource={props?.data?.rows}
//         rowKey="id"
//         pagination={false}
//         bordered
//       />

//       {selectedModule && (
//         <Modal
//           title="Edit Course"
//           visible={isModalVisible}
//           onOk={handleOk}
//           onCancel={handleCancel}
//           width={900}
//           footer={[
//             <Button key="back" onClick={handleCancel}>
//               Cancel
//             </Button>,
//             <Button
//               key="submit"
//               type="primary"
//               onClick={handleOk}
//               loading={isLoading}
//             >
//               Save
//             </Button>,
//           ]}
//         >
//           <Form form={form} layout="vertical">
//             <Form.Item
//               label="Title One"
//               name="titleOne"
//               rules={[
//                 { required: true, message: "Please input the title one!" },
//               ]}
//             >
//               <Input />
//             </Form.Item>
//             <Form.Item
//               label="Title Two"
//               name="titleTwo"
//               rules={[
//                 { required: true, message: "Please input the title two!" },
//               ]}
//             >
//               <Input />
//             </Form.Item>
//             <Form.Item
//               label="Description"
//               name="description"
//               rules={[
//                 { required: true, message: "Please input the description!" },
//               ]}
//             >
//               <Input.TextArea />
//             </Form.Item>
//           </Form>
//         </Modal>
//       )}

//       {viewingModule && (
//         <Modal
//           title="View Module"
//           visible={isViewModalVisible}
//           onCancel={handleViewCancel}
//           width={900}
//           footer={[
//             <Button key="close" onClick={handleViewCancel}>
//               Close
//             </Button>,
//           ]}
//         >
//           <p>
//             <strong>Title One:</strong> {viewingModule.titleOne}
//           </p>
//           <p>
//             <strong>Title Two:</strong> {viewingModule.titleTwo}
//           </p>
//           <p>
//             <strong>Description:</strong> {viewingModule.description}
//           </p>
//         </Modal>
//       )}
//     </>
//   );
// }

// export default ModuleDataTable;
import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Input, Form, notification } from "antd";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { API } from "../../config/api";
import { PUT } from "../../utils/apiCalls";
import LoadingBox from "../../components/loading";
import EmptyBox from "../../components/emptyBox";

function ModuleDataTable(props: any) {
  console.log("props", props);
  const { data, loadData } = props;
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedModule, setSelectedModule] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [viewingModule, setViewingModule] = useState<any>(null);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    const interval = setInterval(() => {
      loadData();
    }, 60000); // Refresh every 60 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [loadData]);

  const showEditModal = (module: any) => {
    setSelectedModule(module);
    form.setFieldsValue(module);
    setIsModalVisible(true);
  };

  const showViewModal = (module: any) => {
    setViewingModule(module);
    setIsViewModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      if (selectedModule?.id) {
        const api = `${API.UPDATE_MODULE}${selectedModule.id}`;
        setIsLoading2(true);
        const updateRequest: any = await PUT(api, values);
        console.log("API response:", updateRequest);

        if (updateRequest.status) {
          notification.success({ message: "Module updated" });
          form.resetFields();
          setIsModalVisible(false);
          loadData();
        } else {
          notification.error({ message: "Failed to update module" });
        }
      } else {
        notification.error({ message: "Module ID is missing" });
      }
    } catch (error) {
      console.error("Form validation failed:", error);
    } finally {
      setIsLoading2(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleViewCancel = () => {
    setIsViewModalVisible(false);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "titleOne",
      key: "titleOne",
      render: (text: string, record: any) => (
        <a
          onClick={() =>
            navigate(`/Admin/admin-addLesson/${record.courseId}/${record.id}`)
          }
          style={{ color: "Blue" }}
        >
          {text}
        </a>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: { id: any }) => (
        <>
          <Button
            icon={<EyeOutlined />}
            onClick={() => showViewModal(record)}
            style={{ marginRight: 8 }}
          />
          <Button
            icon={<EditOutlined />}
            onClick={() => showEditModal(record)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={props?.data?.rows}
        rowKey="id"
        pagination={false}
        bordered
      />

      {selectedModule && (
        <Modal
          title="Edit Course"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={900}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleOk}
              loading={isLoading}
            >
              Save
            </Button>,
          ]}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              label="Title One"
              name="titleOne"
              rules={[
                { required: true, message: "Please input the title one!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Title Two"
              name="titleTwo"
              rules={[
                { required: true, message: "Please input the title two!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                { required: true, message: "Please input the description!" },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Form>
        </Modal>
      )}

      {viewingModule && (
        <Modal
          title="View Module"
          visible={isViewModalVisible}
          onCancel={handleViewCancel}
          width={900}
          footer={[
            <Button key="close" onClick={handleViewCancel}>
              Close
            </Button>,
          ]}
        >
          <p>
            <strong>Title One:</strong> {viewingModule.titleOne}
          </p>
          <p>
            <strong>Title Two:</strong> {viewingModule.titleTwo}
          </p>
          <p>
            <strong>Description:</strong> {viewingModule.description}
          </p>
        </Modal>
      )}
    </>
  );
}

export default ModuleDataTable;

import { createSlice } from "@reduxjs/toolkit";

const CourseSlice = createSlice({
    name: "Course",
    initialState: {
        courseId: null,
        moduleId: null,
        lessonId: null,
        enrolement: {}
    },
    reducers: {
        updateCourse: (state, action) => {
            state.courseId = action.payload.courseId
            state.moduleId = action.payload.moduleId
            state.lessonId = action.payload.lessonId
        },

        updateEnrolement: (state, action) => {
            console.log('action.payload --> ', action.payload);

            state.enrolement = action.payload
        },
    },
});

export const { updateCourse, updateEnrolement } = CourseSlice.actions;
export default CourseSlice.reducer;

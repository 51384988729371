// import React, { useEffect, useState } from "react";
// import { API } from "../../config/api";
// import { GET, PUT } from "../../utils/apiCalls";
// import {
//   Card,
//   Col,
//   Container,
//   Row,
//   Modal,
//   Button,
// } from "react-bootstrap";
// import { useNavigate, useParams } from "react-router-dom";
// import { Form, notification } from "antd";
// import moment from "moment";
// import LoadingBox from "../../components/loading";
// import EmptyBox from "../../components/emptyBox";

// const ViewUsers = () => {
//   const [user, setUser] = useState<any>();
//   const { id } = useParams();
//   const [confirmBlockVisible, setConfirmBlockVisible] = useState(false);
//   const navigate = useNavigate();
//   const [isLoading, setIsLoading] = useState(false);

//   const getUser = async () => {
//     setIsLoading(true);
//     try {
//       let api = API.GET + id;
//       const userData: any = await GET(api, {});
//       setUser(userData?.data);
//     } catch (err) {
//       console.log(err);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const blockUser = async () => {
//     try {
//       let api = API.BLOCK_USER + id;
//       const updateData: any = await PUT(api, user);
//       if (updateData?.status) {
//         setUser({ ...user, status: false });
//         notification.success({ message: "User blocked successfully" });
//       } else {
//         notification.error({ message: "Failed to block user" });
//       }
//     } catch (error) {
//       console.log(error);
//       notification.error({ message: "Something went wrong" });
//     }
//   };

//   const unblockUser = async () => {
//     try {
//       let api = API.UNBLOCK_USER + id;
//       const updateData: any = await PUT(api, user);
//       if (updateData?.status) {
//         setUser({ ...user, status: true });
//         notification.success({ message: "User unblocked successfully" });
//       } else {
//         notification.error({ message: "Failed to unblock user" });
//       }
//     } catch (error) {
//       console.log(error);
//       notification.error({ message: "Something went wrong" });
//     }
//   };

//   useEffect(() => {
//     getUser();
//   }, []);

//   const showConfirmBlockModal = () => {
//     setConfirmBlockVisible(true);
//   };

//   const handleBlockConfirm = () => {
//     blockUser();
//     setConfirmBlockVisible(false);
//   };

//   const handleBlockCancel = () => {
//     setConfirmBlockVisible(false);
//   };

//   const handleUnblock = () => {
//     unblockUser();
//   };

//   const handlenavigate = () => {
//     navigate(-1);
//   };

//   return (
//     <div className="screen-box">
//       <div className="main-box">
//         <div className="main-txt">
//           <Container>
//             {isLoading ? (
//               <LoadingBox />
//             ) : !user ? (
//               <EmptyBox />
//             ) : (
//               <Card className="user-details-card">
//                 <Card.Body>
//                   <center><Card.Title className="user-details-title">User Details</Card.Title></center>
//                   <br/>
//                   <Row className="user-details-row">
//                     <Col md={4} xs={12}>
//                       <strong>Name:</strong>
//                     </Col>
//                     <Col md={8} xs={12}>
//                       <span>{user?.name}</span>
//                     </Col>
//                   </Row>
//                   <br/>
//                   <Row className="user-details-row">
//                     <Col md={4} xs={12}>
//                       <strong>Email:</strong>
//                     </Col>
//                     <Col md={8} xs={12}>
//                       <span>{user?.email}</span>
//                     </Col>
//                   </Row>
//                   <br/>
//                   <Row className="user-details-row">
//                     <Col md={4} xs={12}>
//                       <strong>Username:</strong>
//                     </Col>
//                     <Col md={8} xs={12}>
//                       <span>{user?.username}</span>
//                     </Col>
//                   </Row>
//                   <br/>
//                   <Row className="user-details-row">
//                     <Col md={4} xs={12}>
//                       <strong>Phone:</strong>
//                     </Col>
//                     <Col md={8} xs={12}>
//                       <span>{user?.phone}</span>
//                     </Col>
//                   </Row>
//                   <br/>
//                   <Row className="user-details-row">
//                     <Col md={4} xs={12}>
//                       <strong>DOB:</strong>
//                     </Col>
//                     <Col md={8} xs={12}>
//                       <span>{moment(user?.dob).format("YYYY-MM-DD")}</span>
//                     </Col>
//                   </Row>
//                   <br/>
//                   <Row className="user-details-row">
//                     <Col md={4} xs={12}>
//                       <strong>Status:</strong>
//                     </Col>
//                     <Col md={8} xs={12}>
//                       <span className={user?.status ? "text-success" : "text-danger"}>
//                         {user?.status ? "Active" : "Blocked"}
//                       </span>
//                     </Col>
//                   </Row>
//                   <br/>
//                   <Row className="user-details-row">
//                     <Col xs={12}>
//                       <Form.Item>
//                         {/* {user?.status ? (
//                           <Button variant="danger" onClick={showConfirmBlockModal}>
//                             Block
//                           </Button>
//                         ) : (
//                           <Button variant="success" onClick={handleUnblock}>
//                             Unblock
//                           </Button>
//                         )} */}
//                         <Button variant="primary" onClick={handlenavigate}>Go back</Button>
//                       </Form.Item>
//                     </Col>
//                   </Row>
//                 </Card.Body>
//               </Card>
//             )}
//           </Container>
//         </div>
//       </div>

//     </div>
//   );
// };

// export default ViewUsers;
import React, { useEffect, useState } from "react";
import { API } from "../../config/api";
import { GET, PUT } from "../../utils/apiCalls";
import { Card, Col, Container, Row, Modal, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Form, notification } from "antd";
import moment from "moment";
import LoadingBox from "../../components/loading";
import EmptyBox from "../../components/emptyBox";

const ViewUsers = () => {
  const [user, setUser] = useState<any>();
  const { id } = useParams();
  const [confirmBlockVisible, setConfirmBlockVisible] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const getUser = async () => {
    setIsLoading(true);
    try {
      let api = API.GET + id;
      const userData: any = await GET(api, {});
      setUser(userData?.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const blockUser = async () => {
    try {
      let api = API.BLOCK_USER + id;
      const updateData: any = await PUT(api, user);
      if (updateData?.status) {
        setUser({ ...user, status: false });
        notification.success({ message: "User blocked successfully" });
      } else {
        notification.error({ message: "Failed to block user" });
      }
    } catch (error) {
      console.log(error);
      notification.error({ message: "Something went wrong" });
    }
  };

  const unblockUser = async () => {
    try {
      let api = API.UNBLOCK_USER + id;
      const updateData: any = await PUT(api, user);
      if (updateData?.status) {
        setUser({ ...user, status: true });
        notification.success({ message: "User unblocked successfully" });
      } else {
        notification.error({ message: "Failed to unblock user" });
      }
    } catch (error) {
      console.log(error);
      notification.error({ message: "Something went wrong" });
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const showConfirmBlockModal = () => {
    setConfirmBlockVisible(true);
  };

  const handleBlockConfirm = () => {
    blockUser();
    setConfirmBlockVisible(false);
  };

  const handleBlockCancel = () => {
    setConfirmBlockVisible(false);
  };

  const handleUnblock = () => {
    unblockUser();
  };

  const handlenavigate = () => {
    navigate(-1);
  };

  return (
    <div className="screen-box">
      <div className="main-box">
        <div className="main-txt">
          <Container>
            {isLoading ? (
              <LoadingBox />
            ) : !user ? (
              <EmptyBox />
            ) : (
              <Card className="user-details-card">
                <Card.Body>
                  <center>
                    <Card.Title className="user-details-title">
                      User Details
                    </Card.Title>
                  </center>
                  <br />
                  <Row className="user-details-row">
                    <Col md={4} xs={12}>
                      <strong>Name:</strong>
                    </Col>
                    <Col md={8} xs={12}>
                      <span>{user?.name}</span>
                    </Col>
                  </Row>
                  <br />
                  <Row className="user-details-row">
                    <Col md={4} xs={12}>
                      <strong>Email:</strong>
                    </Col>
                    <Col md={8} xs={12}>
                      <span>{user?.email}</span>
                    </Col>
                  </Row>
                  <br />
                  <Row className="user-details-row">
                    <Col md={4} xs={12}>
                      <strong>Username:</strong>
                    </Col>
                    <Col md={8} xs={12}>
                      <span>{user?.username}</span>
                    </Col>
                  </Row>
                  <br />
                  <Row className="user-details-row">
                    <Col md={4} xs={12}>
                      <strong>Phone:</strong>
                    </Col>
                    <Col md={8} xs={12}>
                      <span>{user?.phone}</span>
                    </Col>
                  </Row>
                  <br />
                  <Row className="user-details-row">
                    <Col md={4} xs={12}>
                      <strong>DOB:</strong>
                    </Col>
                    <Col md={8} xs={12}>
                      <span>{moment(user?.dob).format("YYYY-MM-DD")}</span>
                    </Col>
                  </Row>
                  <br />
                  <Row className="user-details-row">
                    <Col md={4} xs={12}>
                      <strong>Status:</strong>
                    </Col>
                    <Col md={8} xs={12}>
                      <span
                        className={
                          user?.status ? "text-success" : "text-danger"
                        }
                      >
                        {user?.status ? "Active" : "Blocked"}
                      </span>
                    </Col>
                  </Row>
                  <br />
                  <Row className="user-details-row">
                    <Col xs={12}>
                      <Form.Item>
                        {user?.status ? (
                          <Button variant="danger" onClick={blockUser}>
                            Block
                          </Button>
                        ) : (
                          <Button variant="success" onClick={handleUnblock}>
                            Unblock
                          </Button>
                        )}
                        <Button variant="primary" onClick={handlenavigate}>
                          Go back
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}
          </Container>
        </div>
      </div>
    </div>
  );
};

export default ViewUsers;

import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../admin/dashboard";
import { useSelector } from "react-redux";
import ProtectedRoute from "./protectRouter";
import { Layout, theme } from "antd";
import SideBar from "./sideBar";
import LayoutHeader from "./layoutHeader";
import { useNavigate } from "react-router-dom";
import Home from "../app/home";
import Course from "../app/course";
import Enrolement from "../app/enrolements";
import Category from "../app/category";
import Search from "../app/search";
import Details from "../app/course/details";
import Lesson from "../app/Lesson";
import Profile from "../app/profile";
import UpdatePasswords from "../app/profile/updatepassword";
import CourseCategory from "../app/category/coursecategory";
import QuizScreen from "../app/quiz";
import Modules from "../app/modules";
import DownloadHtml from "../app/download";

const Navigation = () => {
  const User = useSelector((state: any) => state.User);
  const [collapsed, setCollapsed] = useState(false);
  const { Sider, Content } = Layout;

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <>
      <Layout>
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <SideBar
            collapsed={collapsed}
            doCollapse={() => setCollapsed(!collapsed)}
          />
        </Sider>

        <Layout>
          <LayoutHeader doCollapse={() => setCollapsed(!collapsed)} />
          <Content style={{ background: "#e3e3e3" }}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/categories" element={<Category />} />
              <Route path="/courses" element={<Course />} />
              <Route path="/enrolements" element={<Enrolement />} />
              <Route path="/search" element={<Search />} />
              <Route path="/profile/" element={<Profile />} />
              <Route path="/update-password" element={<UpdatePasswords />} />
              <Route path="/details/:id" element={<Details />} />
              <Route path="/modules/:courseId" element={<Modules />} />
              <Route path="/lesson/:moduleId" element={<Lesson />} />
              <Route path="/quiz/:moduleId" element={<QuizScreen />} />
              <Route path="/downloads" element={<DownloadHtml />} />
              <Route
                path="categories/coursecategory/:id"
                element={<CourseCategory />}
              />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default Navigation;

import { Card } from "antd";
import React from "react";
import Empty from "../../assets/images/empty.gif";
import "./styles.scss";

const EmptyBox = (props: any) => {
  return (
    <Card
      style={{
        height: "50vh",
      }}
    >
      <div className="NoData-noDataText">
        <div>
          <img src={Empty} alt="Loader" className="NoData-img" />
        </div>
        <div className="NoData-Text">No Data Found</div>
      </div>
    </Card>
  );
};

export default EmptyBox;

// UpdatePasswordForm.js

import React, { useState } from "react";
import { Form, Input, Button, notification } from "antd";
import Navigation from "../../navigation";
import { useNavigate } from "react-router-dom";
import { API } from "../../config/api";
import { POST, PUT } from "../../utils/apiCalls";
import { login } from "../../redux/slice/userSlice";
import { useDispatch, useSelector } from "react-redux";

const UpdatePasswords = () => {
  const dispatch = useDispatch();
  const User: any = useSelector((val: any) => val.User.user);
  const navigate = useNavigate();
  //   const [user, setUser] = useState<any>({
  //     Password: User.password,

  //   });

  const UpdatePasswordForm = async (val: any) => {
    let reqBody = {
      userId: User?.id,
      currentPassword: val?.currentPassword,
      newPassword: val?.newPassword,
      confirmPassword: val?.confirmNewPassword,
    };
    try {
      const api = API.UPDATE_PASSWORD + User.id;
      const updateData: any = await PUT(api, reqBody);
      console.log("first", updateData);
      if (updateData?.status) {
        //   setUser(updateData?.data);
        let _data = updateData?.data;
        dispatch(login({ ..._data }));
        navigate(`/Auth/updatePasswordForm/`);
        notification.success({ message: "password updated successfully" });
      } else {
        //   setUser({});
        notification.error({ message: "Failed to update password" });
      }
    } catch (error) {
      console.log(error);
      notification.error({ message: "Something went wrong" });
    }
  };

  const handleUpdatePassword = () => {
    navigate("/Auth/updatepasswordForm");
  };

  return (
    <div className="update-password-form-container">
      <h2>Update Password</h2>
      <Form
        name="updatePasswordForm"
        onFinish={UpdatePasswordForm}
        layout="vertical"
        initialValues={{ remember: true }}
      >
        <Form.Item
          label="Current Password"
          name="currentPassword"
          rules={[
            { required: true, message: "Please input your current password!" },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="New Password"
          name="newPassword"
          rules={[
            { required: true, message: "Please input your new password!" },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirm New Password"
          name="confirmNewPassword"
          dependencies={["newPassword"]}
          rules={[
            { required: true, message: "Please confirm your new password!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("The two passwords do not match!");
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <button onClick={handleUpdatePassword} className="edit-button">
            Update Password
          </button>
        </Form.Item>
      </Form>
      <Button onClick={() => navigate(-1)}>Go Back</Button>
    </div>
  );
};

export default UpdatePasswords;

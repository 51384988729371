// import { Button, Space, Table, Input } from "antd";
// import React from "react";
// import { useNavigate } from "react-router-dom";
// import { EyeOutlined } from "@ant-design/icons";
// import type { ColumnType } from "antd/es/table";
// import type { FilterDropdownProps } from "antd/es/table/interface";
// import moment from "moment";

// interface DataType {
//   key: string;
//   name: string;
//   email: string;
//   type: boolean;
//   phone: string;
//   dob: string;
// }

// function DataTable({ dataSource, updateStatus }: any) {
//   const navigate = useNavigate();

//   const columns: ColumnType<DataType>[] = [
//     {
//       title: "Name",
//       dataIndex: "name",
//       key: "name",
//       sorter: (a, b) => a.name.localeCompare(b.name),
//       sortDirections: ["ascend", "descend"],
//       filterMultiple: false,
//       onFilter: (value, record) => record.name.includes(value as string),
//       filterDropdown: ({
//         setSelectedKeys,
//         selectedKeys,
//         confirm,
//         clearFilters,
//       }: FilterDropdownProps) => (
//         <div style={{ padding: 8 }}>
//           <Input
//             placeholder="Search name"
//             value={selectedKeys[0]}
//             onChange={(e) =>
//               setSelectedKeys(e.target.value ? [e.target.value] : [])
//             }
//             style={{ marginBottom: 8, display: "block" }}
//           />
//           <Space>
//             <Button
//               type="primary"
//               onClick={() => confirm()}
//               size="small"
//               style={{ width: 90 }}
//             >
//               Search
//             </Button>
//             <Button
//               onClick={() => clearFilters?.()}
//               size="small"
//               style={{ width: 90 }}
//             >
//               Reset
//             </Button>
//           </Space>
//         </div>
//       ),
//     },
//     {
//       title: "Email",
//       dataIndex: "email",
//       key: "email",
//       filterMultiple: false,
//       onFilter: (value, record) => record.email.includes(value as string),
//       filterDropdown: ({
//         setSelectedKeys,
//         selectedKeys,
//         confirm,
//         clearFilters,
//       }: FilterDropdownProps) => (
//         <div style={{ padding: 8 }}>
//           <Input
//             placeholder="Search email"
//             value={selectedKeys[0]}
//             onChange={(e) =>
//               setSelectedKeys(e.target.value ? [e.target.value] : [])
//             }
//             style={{ marginBottom: 8, display: "block" }}
//           />
//           <Space>
//             <Button
//               type="primary"
//               onClick={() => confirm()}
//               size="small"
//               style={{ width: 90 }}
//             >
//               Search
//             </Button>
//             <Button
//               onClick={() => clearFilters?.()}
//               size="small"
//               style={{ width: 90 }}
//             >
//               Reset
//             </Button>
//           </Space>
//         </div>
//       ),
//     },
//     {
//       title: "Role",
//       dataIndex: "type",
//       key: "type",
//       render: (type: boolean) => <span>{type ? "Admin" : "User"}</span>,
//       filterMultiple: false,
//       onFilter: (value, record) =>
//         record.type
//           ? "Admin".includes(value as string)
//           : "User".includes(value as string),
//       filterDropdown: ({
//         setSelectedKeys,
//         selectedKeys,
//         confirm,
//         clearFilters,
//       }: FilterDropdownProps) => (
//         <div style={{ padding: 8 }}>
//           <Input
//             placeholder="Search role"
//             value={selectedKeys[0]}
//             onChange={(e) =>
//               setSelectedKeys(e.target.value ? [e.target.value] : [])
//             }
//             style={{ marginBottom: 8, display: "block" }}
//           />
//           <Space>
//             <Button
//               type="primary"
//               onClick={() => confirm()}
//               size="small"
//               style={{ width: 90 }}
//             >
//               Search
//             </Button>
//             <Button
//               onClick={() => clearFilters?.()}
//               size="small"
//               style={{ width: 90 }}
//             >
//               Reset
//             </Button>
//           </Space>
//         </div>
//       ),
//     },
//     {
//       title: "Phone",
//       dataIndex: "phone",
//       key: "phone",
//     },
//     {
//       title: "DOB",
//       dataIndex: "dob",
//       key: "dob",
//       render: (dob: string) => moment(dob).format("DD-MM-YYYY"),
//     },
//     {
//       title: "Action",
//       key: "action",
//       render: (text: string, item: DataType) => (
//         <Space size="middle">
//           <Button
//             type="link"
//             icon={<EyeOutlined />}
//             onClick={() => navigate(`/Admin/admin-usres/Viewuser/${item.key}`)}
//           />
//         </Space>
//       ),
//     },
//   ];

//   return (
//     <div>
//       <Table
//         scroll={{ x: 100 }}
//         size="small"
//         columns={columns}
//         dataSource={dataSource}
//         pagination={false}
//       />
//     </div>
//   );
// }

// export default DataTable;
// import { Button, Space, Table, Input } from "antd";
// import React from "react";
// import { useNavigate } from "react-router-dom";
// import { EyeOutlined } from "@ant-design/icons";
// import type { ColumnType } from "antd/es/table";
// import type { FilterDropdownProps } from "antd/es/table/interface";
// import moment from "moment";

// interface DataType {
//   key: string;
//   id: number;
//   name: string;
//   email: string;
//   type: boolean;
//   phone: string;
//   dob: string;
// }

// function DataTable({ dataSource, updateStatus }: any) {
//   const navigate = useNavigate();

//   const columns: ColumnType<DataType>[] = [
//     {
//       title: "Name",
//       dataIndex: "name",
//       key: "name",
//       sorter: (a, b) => a.name.localeCompare(b.name),
//       sortDirections: ["ascend", "descend"],
//       filterMultiple: false,
//       onFilter: (value, record) => record.name.includes(value as string),
//       filterDropdown: ({
//         setSelectedKeys,
//         selectedKeys,
//         confirm,
//         clearFilters,
//       }: FilterDropdownProps) => (
//         <div style={{ padding: 8 }}>
//           <Input
//             placeholder="Search name"
//             value={selectedKeys[0]}
//             onChange={(e) =>
//               setSelectedKeys(e.target.value ? [e.target.value] : [])
//             }
//             style={{ marginBottom: 8, display: "block" }}
//           />
//           <Space>
//             <Button
//               type="primary"
//               onClick={() => confirm()}
//               size="small"
//               style={{ width: 90 }}
//             >
//               Search
//             </Button>
//             <Button
//               onClick={() => clearFilters?.()}
//               size="small"
//               style={{ width: 90 }}
//             >
//               Reset
//             </Button>
//           </Space>
//         </div>
//       ),
//     },
//     {
//       title: "Email",
//       dataIndex: "email",
//       key: "email",
//       filterMultiple: false,
//       onFilter: (value, record) => record.email.includes(value as string),
//       filterDropdown: ({
//         setSelectedKeys,
//         selectedKeys,
//         confirm,
//         clearFilters,
//       }: FilterDropdownProps) => (
//         <div style={{ padding: 8 }}>
//           <Input
//             placeholder="Search email"
//             value={selectedKeys[0]}
//             onChange={(e) =>
//               setSelectedKeys(e.target.value ? [e.target.value] : [])
//             }
//             style={{ marginBottom: 8, display: "block" }}
//           />
//           <Space>
//             <Button
//               type="primary"
//               onClick={() => confirm()}
//               size="small"
//               style={{ width: 90 }}
//             >
//               Search
//             </Button>
//             <Button
//               onClick={() => clearFilters?.()}
//               size="small"
//               style={{ width: 90 }}
//             >
//               Reset
//             </Button>
//           </Space>
//         </div>
//       ),
//     },
//     {
//       title: "Role",
//       dataIndex: "type",
//       key: "type",
//       render: (type: boolean) => <span>{type ? "Admin" : "User"}</span>,
//       filterMultiple: false,
//       onFilter: (value, record) =>
//         record.type
//           ? "Admin".includes(value as string)
//           : "User".includes(value as string),
//       filterDropdown: ({
//         setSelectedKeys,
//         selectedKeys,
//         confirm,
//         clearFilters,
//       }: FilterDropdownProps) => (
//         <div style={{ padding: 8 }}>
//           <Input
//             placeholder="Search role"
//             value={selectedKeys[0]}
//             onChange={(e) =>
//               setSelectedKeys(e.target.value ? [e.target.value] : [])
//             }
//             style={{ marginBottom: 8, display: "block" }}
//           />
//           <Space>
//             <Button
//               type="primary"
//               onClick={() => confirm()}
//               size="small"
//               style={{ width: 90 }}
//             >
//               Search
//             </Button>
//             <Button
//               onClick={() => clearFilters?.()}
//               size="small"
//               style={{ width: 90 }}
//             >
//               Reset
//             </Button>
//           </Space>
//         </div>
//       ),
//     },
//     {
//       title: "Phone",
//       dataIndex: "phone",
//       key: "phone",
//     },
//     {
//       title: "DOB",
//       dataIndex: "dob",
//       key: "dob",
//       render: (dob: string) => moment(dob).format("DD-MM-YYYY"),
//     },
//     {
//       title: "Action",
//       key: "action",
//       render: (text: string, item: DataType) => (
//         <Space size="middle">
//           <Button
//             type="link"
//             icon={<EyeOutlined />}
//             onClick={() => navigate(`/Admin/admin-usres/Viewuser/${item.id}`)}
//           />
//         </Space>
//       ),
//     },
//   ];

//   return (
//     <div>
//       <Table
//         scroll={{ x: 100 }}
//         size="small"
//         columns={columns}
//         dataSource={dataSource}
//         pagination={false}
//       />
//     </div>
//   );
// }

// export default DataTable;
// import { Button, Space, Table, Input } from "antd";
// import React from "react";
// import { useNavigate } from "react-router-dom";
// import { EyeOutlined } from "@ant-design/icons";
// import type { ColumnType } from "antd/es/table";
// import type { FilterDropdownProps } from "antd/es/table/interface";
// import moment from "moment";

// interface DataType {
//   key: string;
//   id: number;
//   name: string;
//   email: string;
//   type: boolean;
//   phone: string;
//   dob: string;
//   status: boolean; // Add status to the interface
// }

// function DataTable({ dataSource, updateStatus }: any) {
//   const navigate = useNavigate();

//   const columns: ColumnType<DataType>[] = [
//     {
//       title: "Name",
//       dataIndex: "name",
//       key: "name",
//       sorter: (a, b) => a.name.localeCompare(b.name),
//       sortDirections: ["ascend", "descend"],
//       filterMultiple: false,
//       onFilter: (value, record) => record.name.includes(value as string),
//       filterDropdown: ({
//         setSelectedKeys,
//         selectedKeys,
//         confirm,
//         clearFilters,
//       }: FilterDropdownProps) => (
//         <div style={{ padding: 8 }}>
//           <Input
//             placeholder="Search name"
//             value={selectedKeys[0]}
//             onChange={(e) =>
//               setSelectedKeys(e.target.value ? [e.target.value] : [])
//             }
//             style={{ marginBottom: 8, display: "block" }}
//           />
//           <Space>
//             <Button
//               type="primary"
//               onClick={() => confirm()}
//               size="small"
//               style={{ width: 90 }}
//             >
//               Search
//             </Button>
//             <Button
//               onClick={() => clearFilters?.()}
//               size="small"
//               style={{ width: 90 }}
//             >
//               Reset
//             </Button>
//           </Space>
//         </div>
//       ),
//     },
//     {
//       title: "Email",
//       dataIndex: "email",
//       key: "email",
//       filterMultiple: false,
//       onFilter: (value, record) => record.email.includes(value as string),
//       filterDropdown: ({
//         setSelectedKeys,
//         selectedKeys,
//         confirm,
//         clearFilters,
//       }: FilterDropdownProps) => (
//         <div style={{ padding: 8 }}>
//           <Input
//             placeholder="Search email"
//             value={selectedKeys[0]}
//             onChange={(e) =>
//               setSelectedKeys(e.target.value ? [e.target.value] : [])
//             }
//             style={{ marginBottom: 8, display: "block" }}
//           />
//           <Space>
//             <Button
//               type="primary"
//               onClick={() => confirm()}
//               size="small"
//               style={{ width: 90 }}
//             >
//               Search
//             </Button>
//             <Button
//               onClick={() => clearFilters?.()}
//               size="small"
//               style={{ width: 90 }}
//             >
//               Reset
//             </Button>
//           </Space>
//         </div>
//       ),
//     },
//     {
//       title: "Role",
//       dataIndex: "type",
//       key: "type",
//       render: (type: boolean) => <span>{type ? "Admin" : "User"}</span>,
//       filterMultiple: false,
//       onFilter: (value, record) =>
//         record.type
//           ? "Admin".includes(value as string)
//           : "User".includes(value as string),
//       filterDropdown: ({
//         setSelectedKeys,
//         selectedKeys,
//         confirm,
//         clearFilters,
//       }: FilterDropdownProps) => (
//         <div style={{ padding: 8 }}>
//           <Input
//             placeholder="Search role"
//             value={selectedKeys[0]}
//             onChange={(e) =>
//               setSelectedKeys(e.target.value ? [e.target.value] : [])
//             }
//             style={{ marginBottom: 8, display: "block" }}
//           />
//           <Space>
//             <Button
//               type="primary"
//               onClick={() => confirm()}
//               size="small"
//               style={{ width: 90 }}
//             >
//               Search
//             </Button>
//             <Button
//               onClick={() => clearFilters?.()}
//               size="small"
//               style={{ width: 90 }}
//             >
//               Reset
//             </Button>
//           </Space>
//         </div>
//       ),
//     },
//     {
//       title: "Phone",
//       dataIndex: "phone",
//       key: "phone",
//     },
//     {
//       title: "DOB",
//       dataIndex: "dob",
//       key: "dob",
//       render: (dob: string) => moment(dob).format("DD-MM-YYYY"),
//     },
//     {
//       title: "Status",
//       dataIndex: "status",
//       key: "status",
//       render: (status: boolean) => <span>{status ? "Active" : "Blocked"}</span>,
//     },
//     {
//       title: "Action",
//       key: "action",
//       render: (text: string, item: DataType) => (
//         <Space size="middle">
//           <Button
//             type="link"
//             icon={<EyeOutlined />}
//             onClick={() => navigate(`/Admin/admin-usres/Viewuser/${item.id}`)}
//           />
//         </Space>
//       ),
//     },
//   ];

//   return (
//     <div>
//       <Table
//         scroll={{ x: 100 }}
//         size="small"
//         columns={columns}
//         dataSource={dataSource}
//         pagination={false}
//       />
//     </div>
//   );
// }

// export default DataTable;
import { Button, Space, Table, Input } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import type { ColumnType } from "antd/es/table";
import type { FilterDropdownProps } from "antd/es/table/interface";
import moment from "moment";

interface DataType {
  key: string;
  id: number;
  name: string;
  email: string;
  type: boolean;
  phone: string;
  dob: string;
  status: boolean; // Add status to the interface
}

function DataTable({ dataSource, updateStatus }: any) {
  const navigate = useNavigate();

  const columns: ColumnType<DataType>[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend"],
      filterMultiple: false,
      onFilter: (value, record) => record.name.includes(value as string),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search name"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters?.()}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      filterMultiple: false,
      onFilter: (value, record) => record.email.includes(value as string),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search email"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters?.()}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: "Role",
      dataIndex: "type",
      key: "type",
      render: (type: boolean) => <span>{type ? "Admin" : "User"}</span>,
      filterMultiple: false,
      onFilter: (value, record) =>
        record.type
          ? "Admin".includes(value as string)
          : "User".includes(value as string),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search role"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters?.()}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "DOB",
      dataIndex: "dob",
      key: "dob",
      render: (dob: string) => moment(dob).format("DD-MM-YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: boolean) => (
        <span style={{ color: status ? "green" : "red" }}>
          {status ? "Active" : "Blocked"}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, item: DataType) => (
        <Space size="middle">
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={() => navigate(`/Admin/admin-usres/Viewuser/${item.id}`)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table
        scroll={{ x: 100 }}
        size="small"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </div>
  );
}

export default DataTable;

import React, { useEffect, useRef, useState } from "react";
import { Avatar, Card, Pagination, notification, Button, Modal } from "antd";
import { Col, Row } from "react-bootstrap";
import { API } from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { IoCheckmarkCircle } from "react-icons/io5";
import { CiLock } from "react-icons/ci";
import Meta from "antd/es/card/Meta";
import "./styles.scss";
import Logos from "../modules/image/logo.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import HtmlContent from "./htmlConrtent";
import DownloadButton from "./downloadButton";
import Header from "./header";

const Modules = () => {
  const divRef: any = useRef();
  const contentRef: any = useRef();

  const [page, setPage] = useState(1);
  const [take, setTake] = useState(12);
  const [meta, setMeta] = useState<any>({});
  const [modules, setModules] = useState<any[]>([]);
  const [userProgresses, setUserProgresses] = useState<any>([]);
  const [progressList, setProgressList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [enroll, setEnroll] = useState<any>({});
  const navigate = useNavigate();
  const { courseId } = useParams();
  const User: any = useSelector((val: any) => val.User.user);
  const [showData, setShowData] = useState<any>(null);
  const [lessons, setLesson] = useState<any[]>([]);
  const [progressData, setProgressData] = useState<any[]>([]);
  const [loading2, setLoading2] = useState(false);
  const [courseData, setCourseData] = useState<any>();
  const Course: any = useSelector((val: any) => val.Course);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDownloadPdf = () => {
    const divContent = divRef.current;
    console.log(divContent);

    html2canvas(divContent).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };

  useEffect(() => {
    loadData();
    getProgress();
  }, [page, take]);

  console.log("progressList.......", progressList);

  const loadData = async () => {
    try {
      setLoading2(true);
      const api =
        API.GET_COURSE_MODULE +
        courseId +
        `?order=ASC&page=${page}&take=${take}`;
      const lesson: any = await GET(api, {});
      if (lesson?.status) {
        setModules(lesson?.data?.rows);
        // setCourseData(lesson?.data?.rows);

        console.log("redux Course ======> ", Course);
        let lsId = Course?.enrolement?.lessonId;
        const foundObject = await lesson?.data?.find(
          (obj: any) => obj?.id === String(lsId)
        );
        let newOrder = Number(foundObject?.order);
        const data = await lesson?.data?.find(
          (obj: any) => obj?.order === newOrder
        );
        console.log("final data ====== > ", data);
        setShowData(data);
        setLoading2(false);
      } else {
        setModules([]);
        setLoading2(false);
      }
    } catch (error) {
      console.log(error);
      setLoading2(false);
    }
  };

  const getProgress = async () => {
    try {
      setIsLoading(true);
      const api = "userprogress/progress";
      let body = {
        userId: Number(User?.id),
        courseId: Number(courseId),
        type: "module",
      };
      const progress: any = await POST(api, body);
      if (progress?.status) {
        setUserProgresses(progress?.data);
        let arr: any = [];
        progress?.data?.map((mod: any) => {
          arr.push(String(mod?.moduleId));
        });
        setProgressList(arr);
        setIsLoading(false);
      } else {
        setUserProgresses([]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const pagination = (currentPage: any, pageSize: any) => {
    setPage(currentPage);
    loadData();
  };

  const onClick = (data: any, id: any) => {
    setLoading2(true);
    setModules(data?.rows);
    setLoading2(false);
    if (progressList.includes(id)) {
      navigate(`/Auth/lesson/${Number(id)}`);
    } else {
      notification.warning({ message: "This module is LOCKED" });
    }
  };

  const generateCertificate = () => {
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [792, 612],
    });
    const certificateContent = document.getElementById("certificate-content");

    if (certificateContent) {
      pdf.html(certificateContent, {
        callback: (doc) => {
          doc.save("certificate.pdf");
        },
        x: 20,
        y: 20,
        width: 752,
        windowWidth: 1000,
      });
    } else {
      notification.error({ message: "Certificate content not found" });
    }
  };

  const allModulesUnlocked = modules?.length === progressList.length;

  return (
    <div>
      <div className="main-box">
        <div className="headerBox">
          <Header
            modules={modules}
            btn={allModulesUnlocked}
            download={true}
            header="Modules"
          />
          <br />
        </div>
        <Card className="cat-card">
          <Row>
            {modules?.map((module) => {
              return (
                <Col key={module?.id} md="4">
                  <Card
                    loading={loading2}
                    onClick={() => {
                      if (progressList.includes(module?.id)) {
                        onClick(module, module?.id);
                      } else {
                        notification.warning({
                          message: "This module is Locked!",
                        });
                      }
                    }}
                    className={`module-card ${
                      !progressList.includes(String(module?.id)) ? "locked" : ""
                    }`}
                  >
                    <Meta
                      avatar={
                        progressList?.includes(String(module?.id)) ? (
                          <IoCheckmarkCircle size={25} color={"green"} />
                        ) : (
                          <CiLock size={25} color={"#000"} />
                        )
                      }
                      title={module?.titleOne}
                      description={module?.titleTwo}
                    />
                  </Card>
                </Col>
              );
            })}
          </Row>

          <br />
          <Pagination
            defaultCurrent={page}
            total={meta?.itemCount}
            onChange={(data) => setPage(data)}
          />

          {/* {allModulesUnlocked && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div className="">Certificate</div>
            </div>
          )} */}
        </Card>
      </div>

      {/* <Modal
        width={800}
        title="Download Certificate"
        open={isModalOpen}
        footer={false}
        onCancel={handleCancel}
      >
        <HtmlContent modules={modules} ref={contentRef} />
        <DownloadButton contentRef={contentRef} />
      </Modal> */}
    </div>
  );
};

export default Modules;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../config/api";
import { GET, POST } from "../../utils/apiCalls";
import "./QuizScreen.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faArrowRight,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useDispatch, useSelector } from "react-redux";
import { Module } from "module";
import { updateEnrolement } from "../../redux/slice/courseSlice";

const QuizScreen = () => {
  const { moduleId, courseId } = useParams();
  const [quizData, setQuizData] = useState<any[]>([]);
  const [moduleData, setModulesData] = useState<any>({});

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showSubmit, setShowSubmit] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [correctAnswers, setCorrectAnswers] = useState<string[]>([]);
  const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [score, setScore] = useState(0);
  const [showScoreMessageModal, setShowScoreMessageModal] = useState(false);
  const User: any = useSelector((val: any) => val.User.user);
  const navigate = useNavigate();
  const [showData, setShowData] = useState<any>(null);
  const [loading2, setLoading2] = useState(false);
  const Course: any = useSelector((val: any) => val.Course);
  const dispatch = useDispatch();
  const [enrollment, setEnrollement] = useState<any>({});
  const [userProgresses, setUserProgresses] = useState<any>([]);
  const [progressList, setProgressList] = useState<any>([]);

  useEffect(() => {
    loadData();
    console.log("object", Course);
    getmodule();
  }, []);

  console.log("---------------", moduleData.courseId);

  const loadData = async () => {
    try {
      let url = API.GET_QUIZ + moduleId;
      const quizDataResponse: any = await GET(url, {});
      setQuizData(quizDataResponse?.data);
      setSelectedOptions(new Array(quizDataResponse.length).fill(""));
      setCorrectAnswers(
        quizDataResponse?.data?.map(
          (item: { correctAnswer: any }) => item.correctAnswer
        )
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getmodule = async () => {
    try {
      const api = API.GET_MODULE_DATA + moduleId;

      const response: any = await GET(api, {});

      setModulesData(response);

      console.log("err");
    } catch (err) {
      console.log("object");
    }
  };

  console.log("quiz data------>", quizData);
  console.log("crt Ans------>", correctAnswers);

  const handleOptionSelect = (option: string) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[currentQuestionIndex] = option;
    setSelectedOptions(updatedOptions);
    setShowSubmit(true);
    // Clear the message state
    setMessage("");
    setShowMessage(false);
  };

  const handleSubmit = () => {
    setShowSubmit(false);
    const isCorrect =
      selectedOptions[currentQuestionIndex] ===
      correctAnswers[currentQuestionIndex];
    if (isCorrect) {
      setScore((prevScore) => prevScore + 1);
    } else {
      // setMessage(`Oops! Your answer is incorrect. `);
    }
    setShowMessage(true);
    checkAllQuestionsAnswered();
  };
  console.log(
    "correctAnswers[currentQuestionIndex];---",
    correctAnswers[currentQuestionIndex]
  );

  const handleNextQuestion = () => {
    if (currentQuestionIndex < quizData.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setShowMessage(false);
      setSelectedOptions(new Array(quizData.length).fill(""));
      setMessage("");
    } else {
      setAllQuestionsAnswered(true);
      handleQuizComplete();
    }
  };

  const checkAllQuestionsAnswered = () => {
    const answered = selectedOptions.every((option) => option !== "");
    if (answered) {
      setAllQuestionsAnswered(false);
    }
  };

  const handleQuizComplete = async () => {
    setQuizCompleted(true);
    try {
      const object = {
        userId: Number(User.id),
        moduleId: Number(moduleId),
        completed: true,
        type: "module",
      };
      let api = API.POST_USER_PROGRESS;
      const userProgress: any = await POST(api, object);
      console.log(userProgress);
    } catch (error) {
      console.error("Error updating enrollment:", error);
    }

    const percentageScore = (score / quizData.length) * 100;
    if (percentageScore > 50) {
      console.log("greater than 50%");
      setShowScoreMessageModal(true);
    } else {
      console.log("less than 50%");
      setShowScoreMessageModal(true);
    }
  };

  const restartQuiz = () => {
    setCurrentQuestionIndex(0);
    setSelectedOptions(new Array(quizData.length).fill(""));
    setScore(0);
    setShowMessage(false);
    setMessage("");
    setAllQuestionsAnswered(false);
    setQuizCompleted(false);
  };

  //

  const successIcon: IconProp = faCheck;
  const failureIcon: IconProp = faTimes;

  const getEnrolement = async () => {
    try {
      setLoading2(true);
      let api = "Enrollments/list";
      let obj = {
        userId: Number(User?.id),
        courseId: Number(showData?.courseId),
        page: 1,
        take: 10,
      };
      let enrolement: any = await POST(api, obj);
      if (enrolement?.status) {
        console.log(enrolement?.data[0]);
        setEnrollement(enrolement?.data[0]);
        dispatch(updateEnrolement(enrolement?.data[0]));
        setLoading2(false);
      } else {
        setEnrollement({});
        setLoading2(false);
      }
    } catch (error) {
      console.log(error);
      setLoading2(false);
    }
  };

  const onClickNext = async () => {
    try {
      setLoading2(true);
      let api = "module/nextModule";
      let body = {
        moduleId: Number(Course?.enrolement?.moduleId),
        userId: Number(User.id),
        courseId: Number(Course?.enrolement?.courseId),
        order: moduleData?.order,
      };

      const nextModule: any = await POST(api, body);
      if (nextModule?.status) {
        dispatch(updateEnrolement(nextModule?.data?.enrolement));
        // getEnrolement();
        loadData();
        setLoading2(false);
        navigate(`/Auth/modules/${Course?.enrolement?.courseId}`);
      }
    } catch (error) {
      setLoading2(false);
      console.log(error);
    }
  };

  console.log("qwertyuio", moduleData?.order);
  return (
    <div className="quiz-container">
      {quizData.length > 0 && (
        <div
          className={`question-container ${
            showMessage &&
            selectedOptions[currentQuestionIndex] ===
              correctAnswers[currentQuestionIndex]
              ? "correct-answer"
              : "incorrect-answer"
          }`}
        >
          <h1 className="quiz-heading">Quiz</h1>
          <br />
          <p className="question">{quizData[currentQuestionIndex].question}</p>
          <ul className="options">
            {["option1", "option2", "option3", "option4"].map(
              (optionKey, index) => (
                <li
                  key={index}
                  onClick={() =>
                    handleOptionSelect(
                      quizData[currentQuestionIndex][optionKey]
                    )
                  }
                  className={
                    selectedOptions[currentQuestionIndex] ===
                    quizData[currentQuestionIndex][optionKey]
                      ? "selected"
                      : ""
                  }
                >
                  {quizData[currentQuestionIndex][optionKey]}
                </li>
              )
            )}
          </ul>
          {showSubmit ? (
            <button onClick={handleSubmit} className="submit-button">
              <FontAwesomeIcon icon={successIcon} /> Submit
            </button>
          ) : (
            <button
              onClick={handleNextQuestion}
              className="next-button"
              disabled={!selectedOptions[currentQuestionIndex]}
            >
              Next
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          )}
          <p
            className={`message ${
              showMessage &&
              selectedOptions[currentQuestionIndex] ===
                correctAnswers[currentQuestionIndex]
                ? "correct-answer"
                : "incorrect-answer"
            }`}
          >
            {message}
          </p>
        </div>
      )}
      {quizData.length === 0 && <p>Loading...</p>}

      {/* Score Message Modal */}
      {quizCompleted && (
        <div className="score-message-modal">
          <div className="modal-content">
            <h2>Your Score</h2>
            <p className={score > quizData.length / 2 ? "pass" : "fail"}>
              Your score is: {score}
            </p>
            {score > quizData.length / 2 ? (
              <div>
                <p className="pass-message">
                  Congratulations! You passed the quiz!
                </p>
                <button
                  // onClick={() => navigate(`/Auth/module/${courseId}`)}
                  onClick={() => onClickNext()}
                  className="next-button"
                >
                  <FontAwesomeIcon icon={faAngleDoubleRight} /> Next Module
                </button>
                <button
                  onClick={() =>
                    navigate(`/Auth/modules/${moduleData.courseId}`)
                  }
                  className="next-button"
                >
                  <FontAwesomeIcon icon={faAngleDoubleRight} /> Module
                </button>
              </div>
            ) : (
              <div>
                <p className="fail-message">
                  Sorry, you didn't pass the quiz. Better luck next time!
                </p>
                <button onClick={restartQuiz} className="restart-button">
                  Restart Quiz
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default QuizScreen;

import { IconType } from "react-icons";
import { CgUnavailable } from "react-icons/cg";
import { GiProgression } from "react-icons/gi";
import { MdDashboard, MdOutlineWork } from "react-icons/md";
import { TbBrandBooking } from "react-icons/tb";
import { FaNetworkWired } from "react-icons/fa";
import { IoMdContacts } from "react-icons/io";
import { PiRadioactiveFill } from "react-icons/pi";
import { IoTicketSharp } from "react-icons/io5";
import { BiSolidReport } from "react-icons/bi";
import { RiLoginCircleFill } from "react-icons/ri";
import { MdOutlineWeb } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { FiSearch } from "react-icons/fi";
import { FiHelpCircle } from "react-icons/fi";
import { FiSettings } from "react-icons/fi";




function DynamicIcon(props: any) {
  console.log(props);
  type IconName =
    | "MdDashboard"
    | "TbBrandBooking"
    | "CgUnavailable"
    | "GiProgression"
    | "MdOutlineWork"
    | "FaNetworkWired"
    | "IoMdContacts"
    | "PiRadioactiveFill"
    | "IoTicketSharp"
    | "BiSolidReport"
    | "RiLoginCircleFill"
    | "MdOutlineWeb"
    | "FiSearch"
    | "FiHelpCircle"
    | "FiSettings"
    | "IoMdSettings";

  interface IconProps {
    iconName: IconName;
    size?: number;
    color?: string;
  }

  function Icon({ iconName, size = 25, color = "#0a2f35" }: IconProps) {
    const icons: Record<IconName, IconType> = {
      MdDashboard: MdDashboard,
      TbBrandBooking: TbBrandBooking,
      CgUnavailable: CgUnavailable,
      GiProgression: GiProgression,
      MdOutlineWork: MdOutlineWork,
      FaNetworkWired: FaNetworkWired,
      IoMdContacts: IoMdContacts,
      PiRadioactiveFill: PiRadioactiveFill,
      IoTicketSharp: IoTicketSharp,
      BiSolidReport: BiSolidReport,
      RiLoginCircleFill: RiLoginCircleFill,
      MdOutlineWeb: MdOutlineWeb,
      IoMdSettings: IoMdSettings,
      FiSearch: FiSearch,
      FiHelpCircle: FiHelpCircle,
      FiSettings: FiSettings,
    };

    if (!icons.hasOwnProperty(iconName)) {
      console.warn(
        `Icon '${iconName}' not found. Rendering default icon instead.`
      );
      iconName = "CgUnavailable"; 
    }

    const IconComponent = icons[iconName];

    return <IconComponent size={size}  />;
  }

  return <Icon iconName={props.icon} size={props.size}  />;
}

export default DynamicIcon;
